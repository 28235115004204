import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, CardActionArea } from "@mui/material";

export default function ActionAreaCard({ title, description, src, color, handleClick, selected, onDelete, disabled }) {
  return (
    <Card sx={{ maxWidth: 345 }} onClick={handleClick}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={src && src}
          sx={color && {"backgroundColor":color}}
          alt={src && title}
        />
        <CardContent
        sx={selected && {"backgroundColor": "grey"}}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
            {
              !disabled && onDelete && (
              <Button color="error" aria-label="delete" onClick={(event) => {
                event.stopPropagation();
                onDelete();
              }}>
                <DeleteIcon/>
              </Button>)
            }
          </Typography>
          
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
