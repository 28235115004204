import axios from "axios";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Fragment, useEffect, useState, memo } from "react";
import { API_URL } from "../utils/lib";

function ComboBox({ label, selection, onSelect, url, required, style, idTag, disabled }) {
  const [options, setOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await axios.get(API_URL + url, { withCredentials: true });
      if (active) {
        setOptions([...response.data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const selectionHandler = (e) => {
    onSelect(e);
  };

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        selectionHandler(newValue);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      value={selection}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id }
      disablePortal
      id={`combo-box-${idTag}`}
      sx={style}
      loading={loading}
      disabled={disabled === undefined ? false : disabled}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          size="small"
          required={required}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default memo(ComboBox);