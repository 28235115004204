import {
  OrbitControls,
  PerspectiveCamera,
  useTexture,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { useEffect } from "react";
import { useRef, memo } from "react";
import { Color, FrontSide, BackSide, DoubleSide, LinearFilter, LinearMipMapLinearFilter, RepeatWrapping } from "three";

function MaterialPreview({ mats, data, scale}) {
  return (
    <Canvas linear>
      <ambientLight color={0xffffff} intensity={0.66} />
      <pointLight
        position={[0.0, 1.2, -1.2]}
        intensity={1}
        distance={10}
        decay={2}
        power={5}
        castShadow={true}
      />
      <Box scale={scale} mats={mats} data={data} />
      <PerspectiveCamera makeDefault position={[0, 0, 2]} />
      <OrbitControls makeDefault enableRotate={false} minDistance={0.5} maxDistance={5} enablePan={false} />
    </Canvas>
  );
};

function getSide(side) {
  switch (side) {
    case 0:
      return FrontSide;
    case 1:
      return BackSide;
    case 2:
      return DoubleSide;
    default:
      return null;
  }
}

function getBlending(blending) {
  if (!blending) {
    return 0;
  } else {
    return blending;
  }
}

const Box = memo(function Box({ scale, mats, data }) {
  const materials = useTexture(mats);

  const meshRef = useRef();

  useEffect(() => {
    meshRef.current.material.needsUpdate = true;
  }, [mats, data]);

  //useFrame((state, delta) => (meshRef.current.rotation.y += delta));

  return (
    <mesh ref={meshRef} scale={[scale, scale, scale]}>
      <planeGeometry args={[1, 1]} />

        <meshPhysicalMaterial
          {...materials}
          bumpScale={data.bumpScale ? data.bumpScale : null }
          shininess={data.shininess ? data.shininess : null }
          side={getSide(data.side)}
          blending={getBlending(data.blending)}
          transparent={data.transparent ? data.transparent : null}
          roughness={data.roughness ? data.roughness : null}
          emissive={new Color(data.emissiveColor ? data.emissiveColor : null)}
          emissiveIntensity={data.emissiveIntensity ? data.emissiveIntensity : null}
          opacity={data.opacity === undefined ? 1 : data.opacity}
          thickness={data.thickness ? data.thickness : null}
          clearcoat={data.clearcoat ? data.clearcoat : null}
          clearcoatRoughness={data.clearcoatRoughness ? data.clearcoatRoughness : null}
          metalness={data.metalness ? data.metalness : null}
          color={new Color(data.color ? data.color : null)}
          normalScale={[data.normalScaleX ? data.normalScaleX : null, data.normalScaleY ? data.normalScaleY : null]}
          reflectivity={data.reflectivity ? data.reflectivity : null}
          sheen={data.sheen ? data.sheen : null}
          sheenRoughness={data.sheenRoughness ? data.sheenRoughness : null}
          transmission={data.transmission ? data.transmission : null}
          aoMapIntensity={data.aoMapIntensity ? data.aoMapIntensity : null}
          displacementScale={data.displacementScale ? data.displacementScale : null}
          displacementBias={data.displacementBias ? data.displacementBias : null}
          {...(materials.map ? { 
            map: materials.map, 
            mapMinFilter: LinearMipMapLinearFilter,
            mapMagFilter: LinearFilter,
            mapWrapS: RepeatWrapping,
            mapWrapT: RepeatWrapping,
            mapGenerateMipMaps: true
          } : {})}
          {...(materials.normalMap ? { normalMap: materials.normalMap,             
            normalMinFilter: LinearMipMapLinearFilter,
            normalMagFilter: LinearFilter,
            normalWrapS: RepeatWrapping,
            normalWrapT: RepeatWrapping,
            normalGenerateMipMaps: true } : {})}

          //map-minFilter={LinearMipMapLinearFilter}
          //map-magFilter={LinearFilter}
          //map-wrapS={RepeatWrapping}
          //map-wrapT={RepeatWrapping}
        />

    </mesh>
  );
})

export default MaterialPreview;
