import React, { useEffect, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements
} from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Login from "./Login";
import Navbar from "./Navbar";
import CategoryOverview from "./category/CategoryOverview";
import ColorList from "./color/Color";
import EditColor from "./color/EditColor";
import Intro from "./components/Intro";
import { SnackProvider } from "./components/snackbar/SnackProvider";
import { useLanguage } from "./hooks/useLanguage";
import EditManufacturer from "./manufacturer/EditManufacturer";
import Manufacturer from "./manufacturer/Manufacturer";
import EditMaterial from "./material/EditMaterial";
import Material from "./material/Material";
import EditModel from "./model/EditModel";
import Model from "./model/Model";
import ManufacturerProducts from "./product/ManufacturerProducts";
import Product from "./product/Product";
import ProductOverview from "./product/ProductOverview";
import { createIntlInstance, intlErrorHandler } from "./utils/localesSetup";
import Data from "./data/DataOverview";
import ImageView from "./data/ImageView";
import EditObject from "./object/EditObject";
import Object from "./object/Object";

function App() {
  const { locale } = useLanguage();
  const [messages, setMessages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    createIntlInstance(locale)
      .then(intl => {
        setMessages(intl.messages);
      })
      .catch(error => {
        console.log(error);
      });
  }, [locale]);

  const router = useMemo(() => {
    return createHashRouter(
      createRoutesFromElements(
        <Route path="/" element={<Navbar setLoggedIn={setLoggedIn} loggedIn={loggedIn} setIsAdmin={setIsAdmin} isAdmin={isAdmin} setUserId={setUserId} userId={userId} />}>
          <Route index element={<Home />} />
          <Route path="/manufacturers" element={<Manufacturer />} />
          <Route path="/manufacturer/:id/products" element={<ManufacturerProducts />} />
          <Route path="/manufacturer/:manufacturer_id/product/:id" element={<Product />} />
          <Route path="/manufacturer/:id" element={<EditManufacturer />} />
          <Route path="/products" element={<ProductOverview isAdmin={isAdmin} userId={userId} />} />
          <Route path="/product/:id" element={<Product isAdmin={isAdmin} userId={userId} />} />
          <Route path="/product/:pid/color/:id" element={<EditColor isAdmin={isAdmin} userId={userId} />} />
          <Route path="/product/:pid/material/:id" element={<EditMaterial isAdmin={isAdmin} userId={userId} />} />
          <Route path="/product/:pid/model/:id" element={<EditModel isAdmin={isAdmin} userId={userId} />} />
          <Route path="/colors" element={<ColorList isAdmin={isAdmin} userId={userId} />} />
          <Route path="/color/:id" element={<EditColor isAdmin={isAdmin} userId={userId} />} />
          <Route path="/materials" element={<Material isAdmin={isAdmin} userId={userId} />} />
          <Route path="/material/:id" element={<EditMaterial isAdmin={isAdmin} userId={userId} />} />
          <Route path="/models" element={<Model isAdmin={isAdmin} userId={userId} />} />
          <Route path="/model/:id" element={<EditModel isAdmin={isAdmin} userId={userId} />} />
          <Route path="/data" element={<Data isAdmin={isAdmin} userId={userId} />} />
          <Route path="/categories" element={<CategoryOverview />} />
          <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setIsAdmin={setIsAdmin} setUserId={setUserId} />} />
          <Route path="/image" element={<ImageView />} />
          <Route path="/objects" element={<Object isAdmin={isAdmin} userId={userId} />} />
          <Route path="/object/:id" element={<EditObject isAdmin={isAdmin} userId={userId} />} />
        </Route>
      )
    );
  }, [loggedIn, setLoggedIn]);

  return (
    <IntlProvider
      defaultLocale="de"
      locale={locale}
      messages={messages}
      onError={intlErrorHandler}
    >
      {isLoading && <Intro onEnd={() => setIsLoading(false)} />}
      <SnackProvider>
        <RouterProvider router={router} />
      </SnackProvider>
    </IntlProvider>
  );
}

export default App;
