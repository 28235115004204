import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Button } from "@mui/material";
import { memo, useEffect, useState } from "react";

const ImageBox = memo(({ url, uploaded, onDelete, disabled }) => {
  const [src, setSRC] = useState(url);

  // only change src if necessary, prevents unnecessary reloads
  useEffect(() => {
    if (url && src !== null) {
      setSRC(url);
    }
  }, [url, src]);

  const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      right: 22,
      height: 30,
      top: 20
    },
    maxHeight: '100%',
    width: 'auto'
  }));

  const deleteButtonStyle = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    minWidth: 0,
    padding: '5px',
    cursor: 'pointer',
  };

  const imageStyle = {
    objectFit: 'contain',
    width: '100%',
    maxHeight: '100%',
    height: 'auto'
  }

  return (
    <StyledBadge
      color="primary"
      badgeContent={<CloudUploadIcon />}
      invisible={uploaded}
    ><img alt="preview" style={imageStyle} src={src} />
      {!disabled && <Button style={deleteButtonStyle} color="error" aria-label="delete" onClick={(event) => {
        event.stopPropagation();
        onDelete();
      }}>
        <DeleteIcon />
      </Button>}
    </StyledBadge>
  );
}, (prev, next) => prev.url === next.url && prev.uploaded === next.uploaded && prev.disabled === next.disabled);

export default ImageBox;
