import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function ConfirmationList({ handleClose, handleAccept, title, valueProp, isOpen, options, ...other }) {
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setValue(valueProp);
    }
  }, [valueProp, isOpen]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleOk = () => {
    if (value) {
      handleAccept(value);
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={isOpen}
      onClose={handleClose}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="options"
          name="options"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.id}
              key={option.id}
              control={<Radio />}
              label={option.key}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleCancel} >
          <FormattedMessage id="ConfirmationDialog.no" />
        </Button>
        <Button variant="contained" color="success" onClick={handleOk} autoFocus>
          <FormattedMessage id="ConfirmationDialog.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};