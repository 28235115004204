import { Delete, Done, OpenInFull } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Skeleton } from "@mui/material";
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import CustomSpeedDial from "../components/CustomSpeedDial";
import { API_URL } from "../utils/lib";

export default function DataCard({ data, selectable, onSelect, onDelete, onDownload }) {
  const navigate = useNavigate();
  const [actions, setActions] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const intl = useIntl();

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const openData = useCallback(() => {
    if (data.fileFormat === 'application/pdf') {
      return openPDF();
    } else {
      return openImage();
    }
  }, [data]);

  const openImage = () => {
    navigate(`/image`, { state: `/data/${data.id}/file` });
  }

  const openPDF = () => {
    axios
      .get(`${API_URL}/data/${data.id}/file`, { responseType: 'blob', withCredentials: true })
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
      })
      .catch(error => {
        console.log(error);
      })
  }

  useEffect(() => {
    setActions(() => {
      if (selectable) {
        return [
          { icon: <Done />, name: intl.formatMessage({ id: "Data.select" }), func: onSelect(data) },
          { icon: <OpenInFull />, name: intl.formatMessage({ id: "Data.open" }), func: openData },
          { icon: <DownloadIcon />, name: intl.formatMessage({ id: "Data.download" }), func: onDownload(data) },
        ];
      } else {
        return [
          { icon: <OpenInFull />, name: intl.formatMessage({ id: "Data.open" }), func: openData },
          { icon: <Delete />, name: intl.formatMessage({ id: "Data.delete" }), func: onDelete(data) },
          { icon: <DownloadIcon />, name: intl.formatMessage({ id: "Data.download" }), func: onDownload(data) }
        ];
      }
    });
  }, [selectable, data])

  const d = useMemo(() => {
    if (data.fileFormat === 'application/pdf') {
      return <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><PictureAsPdfIcon sx={{ fontSize: '40px' }} /></div>
    } else {
      return (
        <div style={{ width: '90%', height: '60%' }}>
          {
            !imageLoaded && (
              <Skeleton variant="rectangular" width="100%" height="100%" />
            )
          }
          <img style={{ width: '100%', objectFit: 'contain', maxHeight: '100%', display: imageLoaded ? 'block' : 'none' }} onLoad={handleImageLoad} src={API_URL + `/data/${data.id}/file?quality=thumb`} />
        </div>
      )
    }
  }, [data, imageLoaded]);
  return (
    <Box sx={{ paddingTop: '2px', width: '100%', height: '300px', backgroundColor: '#bfc9c2', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
      {d}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxHeight: '40%' }}>
        <p style={{ width: "calc(100% - 100px)", wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-all', marginLeft: '16px', maxHeight: '100%' }}>{data.fileName}</p>
        <CustomSpeedDial actions={actions} />
      </Box>

    </Box>
  )
}