import styled from '@emotion/styled';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useLanguage } from '../hooks/useLanguage';
import FlagIcon from './FlagIcon';

const customStyles = {
  select: {
    // Style for the Select component
    display: 'flex',
    alignItems: 'center',
    // color: `${theme.palette.immersightGrey.main} !important`,
    color: 'grey'
  },
  menuItem: {
    // Style for the MenuItem component
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    // color: `${theme.palette.immersightGrey.main} !important`,
    color: 'grey'
  },
  label: {
    // Style for the language label
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  box: {
    // Style for the container Box
    paddingLeft: 2,
    display: 'flex',
    alignItems: 'center',
  },
};

const CustomInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    // Style for the Select component
    display: 'flex !important',
    alignItems: 'center',
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    color: 'white',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

// An array of objects representing available languages 
// Check https://github.com/madebybowtie/FlagKit/blob/master/Assets/Flags.md for all flag codes 
const languageOptions = [
  { code: 'de', label: 'DE', flag: 'DE' },
  { code: 'en', label: 'EN', flag: 'GB' },
  // { code: 'cs', label: 'CZ', flag: 'CZ' },
  // { code: 'es', label: 'ES', flag: 'ES' },
  // { code: 'fr', label: 'FR', flag: 'FR' },
  // { code: 'it', label: 'IT', flag: 'IT' },
  // { code: 'pl', label: 'PL', flag: 'PL' },
  // { code: 'pt', label: 'PT', flag: 'PT' },
];

function LanguagePicker() {
  // Initialize state for the currently selected language
  const { locale, setLocale } = useLanguage();
  const [language, setLanguage] = useState(locale);

  // Define a memoized callback function to handle language changes
  const handleLanguageChange = useCallback((event) => {
    setLocale(event.target.value)
    setLanguage(event.target.value);
  }, []);

  const menuItems = useMemo(() => {
    return languageOptions.map(({ code, label, flag }) => (
      <MenuItem key={code} value={code} sx={customStyles.menuItem}>
        <FlagIcon code={flag} sx={customStyles.icon} />
        <Typography variant="body1" sx={customStyles.label}>{label}</Typography>
      </MenuItem>
    ));
  }, [customStyles]);

  return (
    <Box sx={customStyles.box}>
      <Select
        // Set the value of the Select component to the currently selected language
        value={language}
        // Call the handleLanguageChange callback when the language is changed
        onChange={handleLanguageChange}
        input={<CustomInput />}
        // Remove the border from the outlined input
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 0
          },
          display: "flex !important",
          alignItems: "center",
        }}
      >
        {menuItems}
      </Select>
    </Box>
  );
}

export default LanguagePicker;
