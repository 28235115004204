import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Fragment, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { API_URL } from "../utils/lib";

export default function ProductObjects({ isStatic, objects, addObjectToProduct, removeObject }) {

  const [object, setObject] = useState({ name: '' });
  const [open, setOpen] = useState(false);

  const [allObjectOptions, setAllObjectOptions] = useState([]);
  const [objectOptions, setObjectOptions] = useState([]);

  const intl = useIntl();

  /**
   * Fetch all object-options from server.
   */
  useEffect(() => {
    let active = true;
    const idList = objects.map(ob => ob.id);
    if (objectOptions.length === 0) {
      axios.get(`${API_URL}/object/all`, { withCredentials: true })
        .then(response => {
          if (!active) return;
          setAllObjectOptions(response.data);
          setObjectOptions(response.data.filter(ob => !idList.includes(ob.id)));
        });
    } else {
      setObjectOptions(allObjectOptions.filter(ob => !idList.includes(ob.id)));
    }
    return () => {
      active = false;
    }
  }, [objects]);

  const handleObjectChange = (value) => {
    setObject(old => {
      let obj = {};
      if (value.name) {
        // the object is predefined (fetched from the server)
        obj = { ...value }
      } else {
        obj = { name: value }
      }
      return obj;
    });
  };

  /**
   * Adds the currently selected object to the objects-array.
   * @returns 
   */
  const addObject = (object) => {
    let obj = {};
    if (!object.id) {
      // search for object with same name
      obj = objectOptions.find(ob => ob.name === object.name)
      if (!obj) {
        // no object with specified name found
        return;
      }
    } else {
      obj = { ...object };
    }
    // add object to objects
    axios
      .get(`${API_URL}/object/${obj.id}`, { withCredentials: true })
      .then(response => {
        // add object
        addObjectToProduct(response.data);
      })
      .catch(error => {
        console.log(error);
      })
    // clear object
    setObject({ name: '' });
  };

  /**
   * The react-elements of all objects that are associated with the product.
   */
  const objectList = useMemo(() => {
    return objects.map(ob => {
      return <Grid key={ob.id} item xs={12} sx={{ marginTop: 1, marginBottom: 1 }} alignItems="center" >
        {!isStatic && <Button
          variant="outlined"
          onClick={removeObject(ob)}
          color="error"
          aria-label="delete"
        >
          <DeleteIcon />
        </Button>}
        <p style={{ marginLeft: 5, display: 'inline-block' }} >{ob.name}</p>
      </Grid>
    })
  }, [objects, isStatic]);

  return <Grid item xs={12} container spacing={0} alignItems="center" >
    <Grid item xs={12} >
      <Typography variant="h6" gutterBottom>
        <FormattedMessage id="EditProduct.objectHeading" />
      </Typography>
    </Grid>
    {!isStatic &&
      <Grid item xs={12} container spacing={3} >
        <Grid item xs={6} >
          <Autocomplete
            freeSolo
            value={object}
            inputValue={object.name}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={(event, value) => {
              handleObjectChange(value);
            }}
            onChange={(event, value) => {
              if (!value) {
                handleObjectChange('');
                return;
              }
              handleObjectChange(value);
              addObject(value);
            }}
            options={objectOptions}
            getOptionLabel={(option) => {
              if (option.name !== undefined) {
                return option.name;
              } else {
                return option;
              }
            }}
            onKeyDown={(event, value) => {
              if (event.key === 'Enter') {
                // when enter is pressed, try to add the object
                event.preventDefault();
                addObject(object);
              }
            }}
            id={`combo-box-key`}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                size="small"
                {...params}
                label={intl.formatMessage({ id: "EditProduct.object" })}
                sx={{
                  width: {
                    xs: '95%',
                    sm: '90%',
                    md: '95%'
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={option.id} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  {option.name}
                </li>
              )
            }}
          >
          </Autocomplete>
        </Grid>
        <Grid item xs={6} container alignItems="center" >
          <Button variant="outlined" sx={{
            width: {
              xs: '80%',
              sm: '50%',
              md: '50%'
            }
          }} onClick={() => addObject(object)}><FormattedMessage id="EditProduct.addProductObject" /></Button>
        </Grid>
      </Grid>}
    <Grid item xs={12} container direction="row" >
      {objectList}
    </Grid>
  </Grid>
}