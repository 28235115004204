import {
  Box,
  Button,
  Container,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useSnackbar from "./components/snackbar/useSnackBar";
import { API_URL } from "./utils/lib";

export default function Login({ setLoggedIn, setIsAdmin, setUserId }) {

  const navigate = useNavigate();

  const intl = useIntl();

  const { openSnackBar, closeSnackBar } = useSnackbar();

  useEffect(() => {
    setLoggedIn(false);
    setIsAdmin(false);
    setUserId(null);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const body = {
      username: data.get("username"),
      password: data.get("password")
    }

    axios
      .post(API_URL + '/login', body, { withCredentials: true })
      .then(response => {
        setLoggedIn(true);
        setIsAdmin(response.data.isAdmin);
        setUserId(response.data.userId)
        navigate('/');
      })
      .catch(error => {
        console.log(error);
        setLoggedIn(false);
        setIsAdmin(false);
        setUserId(null);
        openSnackBar(intl.formatMessage({ id: "Login.wrongUsernamePassword" }));
      })
  };

  const params = new URLSearchParams(window.location.search)

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" error={params.get('error')} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label={intl.formatMessage({ id: "Login.emailLabel" })}
            name="username"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={intl.formatMessage({ id: "Login.passwordLabel" })}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Eingeloggt bleiben"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
