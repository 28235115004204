import { useTheme } from "@emotion/react";
import React from "react";

const FlagIcon = (props) => {
  const { code } = props;

  const theme = useTheme();
  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    width: "2rem",
    height: "1.66rem",
    marginRight: theme.spacing(1),
    boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.03)",
    alignSelf: "center"
  };

  const getIcon = () => {
    const iconName = code.toLowerCase();
    const iconPath = `/img/flags/${iconName}.svg`;
    return <img src={iconPath} alt={code} style={iconStyle} />;
  };

  return (getIcon());
}

export default FlagIcon;
