import AbcIcon from '@mui/icons-material/Abc';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import NumbersIcon from '@mui/icons-material/Numbers';
import RuleIcon from '@mui/icons-material/Rule';
import { Autocomplete, Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../utils/lib";

export default function EditObject({ isAdmin, userId }) {
  const { id } = useParams();

  const [data, setData] = useState({ attributes: [] });
  const [selectedAttribute, setSelectedAttribute] = useState({ key: '', type: 'PLAIN' });
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [atToDelete, setAtToDelete] = useState([]);
  const loading = open && attributeOptions.length === 0;
  const attributeId = useRef(-1);

  const intl = useIntl();

  const navigate = useNavigate();

  const getNextAttributeId = () => {
    return attributeId.current--;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = { ...data };
    obj.attributes = getAttributeData();
    setIsLoading(true);
    if (id === "new") {
      axios
        .post(`${API_URL}/object`, obj, { withCredentials: true })
        .then((response) => {
          navigate('/objects');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // check if there are attribute-relations that must be deleted beforehand
      let atDeletionPromise = new Promise((resolve, reject) => resolve());
      if (atToDelete.length > 0) {
        atDeletionPromise = Promise.all(atToDelete.map(at => {
          return axios.delete(`${API_URL}/object/${id}/attribute/${at.id}`, { withCredentials: true })
        }));
        // clear attribute-deletion-list again
        setAtToDelete([]);
      }
      atDeletionPromise
        .then(() => {
          return axios
            .put(`${API_URL}/object/${id}`, obj, { withCredentials: true })
        })
        .then((response) => {
          navigate('/objects');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Transforms the attributes that have been changed to objects that can be used in requests.
   * @returns the attributes
   */
  const getAttributeData = () => {
    return data.attributes.map(attribute => {
      let at = { ...attribute };
      if (at.uploaded) {
        // attribute is already uploaded
        return at;
      }
      // check if there is an attribute from the server with the same key and type
      let option = attributeOptions.find(option => option.key === at.key && option.type === at.type);
      if (option) {
        // set this option as attribute
        at = option;
      } else {
        // no option matches
        at.id = null;
      }
      return at;
    }).filter(at => at !== null);
  }

  useEffect(() => {
    let active = true;
    if (id !== "new") {
      let fetchURL = '';
      fetchURL += `/object/${id}`
      setIsLoading(true);
      axios
        .get(API_URL + fetchURL, { withCredentials: true })
        .then(response => {
          if (!active) return;
          parseData(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          if (!active) return;
          setIsLoading(false);
        });
    }
    // fetch all attributes
    fetchAllAttributes();
    return () => {
      // cancel state-setting-methods
      active = false;
    }
  }, [id]);

  /**
   * Parse the fetched data.
   * @param {*} data 
   */
  const parseData = (data) => {
    setData(() => {
      return {
        ...data,
        attributes: data.attributes.map(at => ({ ...at, associated: true }))
      }
    })
  }

  /**
   * Fetches all attributes from the server.
   */
  const fetchAllAttributes = () => {
    axios.get(`${API_URL}/attribute`, { withCredentials: true })
      .then(response => {
        setAttributeOptions([...response.data].map(at => ({ ...at, uploaded: true })));
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Call this method when data is changed.
   * @param {*} event 
   */
  const changeData = (event) => {
    setData(oldData => ({ ...oldData, [event.target.name]: event.target.value }));
  };

  /**
   * Handle, when the key of the attribute changes.
   * @param {*} value 
   */
  const handleAttributeKeyChange = (value) => {
    setSelectedAttribute(attribute => {
      let obj = {};
      if (value.key) {
        // the attribute is predefined (fetched from the server)
        obj = { ...value };
      } else {
        // the attribute is not defined yet, keep the old attribute-type
        obj = { type: attribute.type, key: value };
      }
      return obj;
    });
  };

  /**
   * Handle, when the type of the attribute changes.
   * @param {*} type 
   */
  const handleAttributeTypeChange = (type) => {
    setSelectedAttribute(attribute => ({ key: attribute.key, type: type }));
  };

  /**
   * Places the specified attribute on the atToDelete-list, if association already on server.
   * Removes the attribute from the data.
   * @param {*} attribute 
   */
  const handleAttributeDelete = (attribute) => () => {
    if (attribute.associated) {
      // attribute is already associated on server, must issue delete request when saving
      setAtToDelete(old => [...old, attribute]);
    }
    setData(old => ({ ...old, attributes: old.attributes.filter(at => at.id !== attribute.id) }));
  }

  /**
   * Adds the attribute in selectedAttribute to the attribute-list of this object.
   * @returns 
   */
  const addAttribute = () => {
    if (!selectedAttribute.key || !selectedAttribute.type) return;
    let obj = { ...selectedAttribute };
    if (obj.id === null) {
      obj.id = getNextAttributeId();
    }
    setData(oldData => ({ ...oldData, attributes: [...oldData.attributes, obj] }));
    setSelectedAttribute({ key: '', type: 'PLAIN' });
  }

  /**
   * Returns the icon for the specified attribute-type.^
   * @param {*} type 
   * @returns 
   */
  const getAttributeTypeIcon = (type) => {
    switch (type) {
      case 'NUMBER':
        return <NumbersIcon sx={{ color: 'gray' }} />;
      case 'TEXT':
        return <AbcIcon sx={{ color: 'gray' }} />;
      case 'BOOL':
        return <RuleIcon sx={{ color: 'gray' }} />;
      default:
        return <BlockIcon sx={{ color: 'gray' }} />;
    }
  }

  /**
   * A list of all attribute-elements that are associated with the object.
   */
  const attributeElements = useMemo(() => {
    return data.attributes.map(at => {
      return <Grid key={at.id} item xs={12} container direction="row" alignItems="center" spacing={2} >
        <Grid item xs={6} sm={4} md={2} >
          {at.key}
        </Grid>
        <Grid item xs={2} sm={1} md={1} style={{ display: 'flex', alignItems: 'center' }} >
          {getAttributeTypeIcon(at.type)}
        </Grid>
        <Grid item xs={2} sm={1} md={1} >
          <Button
            variant="outlined"
            onClick={handleAttributeDelete(at)}
            color="error"
            aria-label="delete"
          >
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    });
  }, [data]);

  const textFieldStyle = {
    width: '90%'
  }

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        <FormattedMessage id="EditObject.heading" />
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, margin: 0, width: "100%", paddingBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"name"}
              label={intl.formatMessage({ id: "EditObject.name" })}
              type="text"
              sx={textFieldStyle}
              value={data.name || ''}
              onChange={changeData}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom><FormattedMessage id="EditObject.attributes" /></Typography>
          </Grid>
          <Grid item xs={12} container spacing={3} alignItems="center" >
            <Grid item xs={12} sm={6} md={3} >
              <Autocomplete
                freeSolo
                value={selectedAttribute}
                inputValue={selectedAttribute.key}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onInputChange={(event, value) => {
                  handleAttributeKeyChange(value);
                }}
                onChange={(event, value) => {
                  if (!value) {
                    handleAttributeKeyChange('');
                    return;
                  }
                  handleAttributeKeyChange(value);
                }}
                onKeyDown={(event, value) => {
                  if (event.key === 'Enter') {
                    // when enter is pressed, try to add the object
                    event.preventDefault();
                    addAttribute();
                  }
                }}
                options={attributeOptions}
                getOptionLabel={(option) => {
                  if (option.key !== undefined) {
                    return option.key;
                  } else {
                    return option;
                  }
                }}
                id={`combo-box-key`}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    size="small"
                    {...params}
                    label={intl.formatMessage({ id: "AttributeBox.key" })}
                    sx={textFieldStyle}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  let icon = getAttributeTypeIcon(option.type);
                  return (
                    <li {...props} key={option.id} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                      {option.key}
                      {icon}
                    </li>
                  )
                }}
              >
              </Autocomplete>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <FormControl fullWidth>
                <InputLabel id="attribute-type-select-label"><FormattedMessage id="AttributeBox.type" /></InputLabel>
                <Select
                  labelId="attribute-type-select-label"
                  id="attribute-type-select"
                  value={selectedAttribute.type}
                  label={intl.formatMessage({ id: "AttributeBox.type" })}
                  sx={textFieldStyle}
                  onChange={(event) => handleAttributeTypeChange(event.target.value)}
                >
                  <MenuItem value={'PLAIN'}><em><FormattedMessage id="AttributeBox.noValue" /></em></MenuItem>
                  <MenuItem value={'TEXT'}><FormattedMessage id="AttributeBox.text" /></MenuItem>
                  <MenuItem value={'NUMBER'}><FormattedMessage id="AttributeBox.number" /></MenuItem>
                  <MenuItem value={'BOOL'}><FormattedMessage id="AttributeBox.bool" /></MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} >
              <Button variant="outlined" onClick={addAttribute} ><FormattedMessage id="EditObject.addAttribute" /></Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom><FormattedMessage id="EditObject.attributeList" /></Typography>
          </Grid>
          <Grid item container xs={12} direction="row" spacing={2} >
            {attributeElements}
          </Grid>
        </Grid>
        <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>
      </Box>
    </Container>
  );
}
