import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function ConfirmationDialog({ isOpen, handleAccept, handleClose, title, content }) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}><FormattedMessage id="ConfirmationDialog.no" /></Button>
        <Button variant="contained" color="success" onClick={handleAccept} autoFocus>
          <FormattedMessage id="ConfirmationDialog.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
