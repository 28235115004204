import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Slider, Stack, styled } from "@mui/material";

export default function ZoomSlider({ zoom, setZoom }) {

  const StyledZoomInIcon = styled(ZoomInIcon)({
    borderRadius: '45px',
    transition: 'background-color 0.2s linear',
    '&:hover': {
      backgroundColor: 'silver'
    }
  });

  const StyledZoomOutIcon = styled(ZoomOutIcon)({
    borderRadius: '45px',
    transition: 'background-color 0.2s linear',
    '&:hover': {
      backgroundColor: 'silver'
    }
  });

  return <Stack spacing={2} direction="column" sx={{ alignItems: 'center', height: '100%', justifyContent: 'center' }}>
    <StyledZoomInIcon onClick={() => setZoom(oldValue => Math.max(oldValue - 5, 0.01))} />
    <Slider aria-label="ModelZoom" value={50 - zoom} onChange={(event, newValue) => setZoom(50 - newValue)}
      min={0}
      max={49.9}
      shiftStep={1}
      step={0.01}
      orientation="vertical"
      style={{ height: '80%' }}
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: 'slider-vertical'
        },
      }}
    />
    <StyledZoomOutIcon onClick={() => setZoom(oldValue => Math.min(oldValue + 5, 50))} />
  </Stack>
}