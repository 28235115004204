import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Badge, Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { API_URL } from "../utils/lib";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 22,
    height: 30,
    top: 20,
  },
}));

const deleteButtonStyle = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  minWidth: 0,
  padding: '5px',
  cursor: 'pointer',
};

function ImageCard({ image, removeData, disabled, id }) {
  const imageStyle = {
    objectFit: 'contain',
    width: '100%',
    maxHeight: '300px',
    height: 'auto'
  }

  return (
    <StyledBadge
      color="primary"
      badgeContent={<CloudUploadIcon />}
      invisible={image.notUploaded !== true}
      sx={{ width: '100%' }}
    >
      <img src={!image.notUploaded ? `${API_URL}/product/${id}/data/${image.id}?quality=thumb` : `${API_URL}/data/${image.id}/file?quality=thumb`} style={imageStyle}></img>

      {!disabled && <Button style={deleteButtonStyle} color="error" aria-label="delete" onClick={() => removeData(image)}>
        <DeleteIcon />
      </Button>}
    </StyledBadge>
  );
}

function PDFCard({ pdf, removeData, disabled, id }) {
  return (
    <StyledBadge
      color="primary"
      badgeContent={<CloudUploadIcon />}
      invisible={pdf.notUploaded !== true}
      sx={{ width: '100%' }}
    >
      <Box width={"100%"} boxSizing={'border-box'} padding={'5px'} >
        <Box display={'flex'} columnGap={1} marginBottom={2} >
          <PictureAsPdfIcon />
          <Typography>{pdf.filename}</Typography>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} columnGap={1}>
          <Button color="primary" size="small" sx={{ minWidth: 0, padding: 0, margin: 0 }} aria-label="open" onClick={() => {
            // make server-request, create object-url and open url in new tab
            if (pdf.id < 0) {
              // deprecated, was used for files that were uploaded directly
              const pdfUrl = window.URL.createObjectURL(pdf);
              window.open(pdfUrl, '_blank');
            } else if (pdf.notUploaded) {
              axios
                .get(`${API_URL}/data/${pdf.id}/file`, { responseType: 'blob', withCredentials: true })
                .then(response => {
                  const blob = new Blob([response.data], { type: response.headers['content-type'] });
                  const pdfUrl = window.URL.createObjectURL(blob);
                  window.open(pdfUrl, '_blank');
                });
            } else {
              axios
                .get(`${API_URL}/product/${id}/data/${pdf.id}`, { responseType: 'blob', withCredentials: true })
                .then(response => {
                  const blob = new Blob([response.data], { type: response.headers['content-type'] });
                  const pdfUrl = window.URL.createObjectURL(blob);
                  window.open(pdfUrl, '_blank');
                });
            }
          }}>
            <OpenInNewIcon />
          </Button>
          {!disabled && <Button color="error" size="small" sx={{ minWidth: 0, padding: 0, margin: 0 }} aria-label="delete" onClick={() => removeData(pdf)}>
            <DeleteIcon />
          </Button>}
        </Box>
      </Box>
    </StyledBadge>
  );
}

export default function ProductFiles({ removeData, extraData, disabled, id }) {
  const [images, setImages] = useState([]);
  const [pdfs, setPDFs] = useState([]);

  useEffect(() => {
    setImages([...extraData.filter(data => !data.fileFormat.toLowerCase().includes('pdf'))]);
    setPDFs([...extraData.filter(data => data.fileFormat.toLowerCase().includes('pdf'))]);
  }, [extraData])

  return (
    <Grid container alignItems={"flex-end"} spacing={1} >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="ProductFiles.pictures" />
        </Typography>
      </Grid>
      {images.map((image) => {
        return (
          <Grid key={image.id} item xs={6} md={3} >
            <ImageCard image={image} removeData={removeData} disabled={disabled} id={id} />
          </Grid>
        )
      })}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="ProductFiles.pdfs" />
        </Typography>
      </Grid>
      {pdfs.map((pdf) => {
        return (
          <Grid key={pdf.id} item xs={6} md={3}>
            <PDFCard pdf={pdf} removeData={removeData} disabled={disabled} id={id} />
          </Grid>
        )
      })}
    </Grid>
  )
}