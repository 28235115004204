import { Box, CircularProgress, Grid, Pagination } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import ActionAreaCard from "../components/ActionAreaCard";
import SearchField from "../components/SearchField";
import { API_URL } from "../utils/lib";

export default function ConcreteAssetOverview({ onAdd, onRemove, type, getSelectedAssets }) {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/${type}?page=${page - 1}&size=${pageSize}&search=${query}&assetType=${type}`, { withCredentials: true })
      .then((response) => {
        const selectedAssets = getSelectedAssets();
        setAssets(response.data.content.map(asset => {
          if (selectedAssets.find(a => a.id === asset.id)) {
            return { ...asset, selected: true }
          } else {
            return asset;
          }
        }));
        setCount(response.data.totalPages - 1);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [query, page, pageSize, type]);

  /**
   * Updates selected assets based off current state.
   */
  useEffect(() => {
    const selectedAssets = getSelectedAssets();
    setAssets(oldAssets => oldAssets.map(asset => {
      if (selectedAssets.find(a => a.id === asset.id)) {
        return { ...asset, selected: true }
      } else {
        return { ...asset, selected: false };
      }
    }))
  }, [getSelectedAssets]);

  const clickHandler = (asset) => {
    if (asset.selected) {
      delete asset.selected;
      onRemove(asset);
    } else {
      asset.selected = true;
      onAdd(asset);
    }
  };

  const handleSearch = (value) => {
    setPage(1);
    setQuery(value);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <SearchField onChange={(value) => handleSearch(value)} />

      <Pagination
        size="large"
        count={count}
        page={page}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={handleChange}
      />

      <Grid container spacing={3} >
        {assets.map((asset, idx) => {
          const assetType = asset.assetType.toLowerCase();
          return (
            <Grid key={idx} item xs={3}>
              <ActionAreaCard
                title={asset.name}
                description={asset.description}
                src={
                  asset.previewPictureId &&
                  `${API_URL}/${assetType}/${asset.id}/previewPicture?quality=thumb`
                }
                color={asset.hex && asset.hex}
                handleClick={() => clickHandler(asset)}
                selected={asset.selected}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <CircularProgress color="primary" />}
      </Box>
    </Box>
  );
}