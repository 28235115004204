import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import mapTypes from "./MapUtil";

export default function MaterialImageCard({ id, url, map, handle, removeMap, handleTypeChange, disabled }) {
  const [mapType, setMapType] = useState(map.type ? map.type : '');

  const intl = useIntl();

  function handleDelete() {
    removeMap(map);
  }

  function handleChange(event) {
    if (handleTypeChange(map.id, event.target.value)) {
      setMapType(event.target.value);
    }
  }

  const deleteButtonStyle = {
    float: 'right',
    minWidth: 0,
    paddingBottom: 15,
    cursor: 'pointer',
  };

  return (
    <Card sx={{ width: '90%' }} >
      <div style={{
        width: '100%',
        height: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}>
        <CloudUploadIcon color="primary" sx={{
          position: "absolute",
          top: 10,
          right: 20,
          display: map.id !== undefined && map.id >= 0 ? 'none' : 'block'
        }} />
        <img src={url} style={{
          height: '100%',
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain'
        }} />
      </div>
      <CardContent>
        <Typography gutterBottom variant="body1" component="div">
          <FormControl fullWidth size="small">
            <InputLabel id="maptype-label"><FormattedMessage id="MaterialImageCard.type" /></InputLabel>
            <Select
              labelId="maptype-label"
              id="maptype"
              value={mapType}
              label={intl.formatMessage({ id: "MaterialImageCard.type" })}
              required={true}
              onChange={handleChange}
              disabled={disabled}
            >
              {mapTypes.map((map, i) => {
                return (
                  <MenuItem key={i} value={map.type}>
                    {map.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Typography>
        {!disabled && <Button style={deleteButtonStyle} color="error" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </Button>}
      </CardContent>
    </Card>
  );
}
