import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  ButtonGroup,
  Container,
  FormControlLabel,
  Pagination,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SearchField from "../components/SearchField";
import useSnackbar from "../components/snackbar/useSnackBar";
import { API_URL } from "../utils/lib";

export default function Model({ isAdmin, userId }) {
  const [models, setModels] = useState([]);
  const [page, setPage] = useState(1);
  // number of pages
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [query, setQuery] = useState("");
  // if the dialog for deletion is open
  const [isOpen, setIsOpen] = useState({ state: false, id: null });

  const [filterPublished, setFilterPublished] = useState(false);

  const intl = useIntl();

  const navigate = useNavigate();

  const handleOpen = (id) => {
    setIsOpen({ state: true, id: id });
  };

  const handleClose = () => {
    setIsOpen({ state: false });
  };

  const dialog = useMemo(() => {
    let d = (
      <ConfirmationDialog
        title={intl.formatMessage({ id: "Model.deletionQuestion" })}
        content={intl.formatMessage({ id: "Overview.deletionInfo" })}
        isOpen={isOpen.state}
        handleAccept={() => handleDelete(isOpen.id)}
        handleClose={handleClose}
      />
    );
    if (isOpen.state) {
      return d;
    }
  }, [isOpen]);

  function handleDelete(id) {
    axios
      .delete(`${API_URL}/model/${id}`, { withCredentials: true })
      .then((response) => {
        // handle the response
        handleClose();
        setModels(oldModels => oldModels.filter(i => i.id !== id));
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        handleClose();
        openSnackBar(intl.formatMessage({ id: "Overview.deletionFailure" }));
      });
  }

  const { openSnackBar, closeSnackBar } = useSnackbar();

  useEffect(() => {
    let fetchURL = '/model';
    fetchURL += `?page=${page - 1}&size=${pageSize}`
    if (query) {
      fetchURL += `&search=${query}`
    }
    fetchURL += `&published=${filterPublished}`;
    axios
      .get(API_URL + fetchURL, { withCredentials: true })
      .then(response => {
        setModels(response.data.content);
        setCount(response.data.totalPages);
      })
      .catch(error => {
        console.log(error);
        navigate('/login');
      });

  }, [page, pageSize, query, filterPublished]);

  const handleSearch = (value) => {
    setPage(1);
    setQuery(value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="success"
        component={Link}
        to="/model/new"
        sx={{ float: "right" }}
      >
        <FormattedMessage id="Overview.create" />
      </Button>
      <h3><FormattedMessage id="Model.heading" /></h3>
      <SearchField onChange={(value) => handleSearch(value)} />
      <FormControlLabel
        sx={{ m: 1 }}
        value="end"
        control={<Switch color="primary" checked={filterPublished} onChange={event => setFilterPublished(event.target.checked)} />}
        label={intl.formatMessage({ id: "Model.filterPublished" })}
        labelPlacement="end"
      />
      <Pagination
        size="large"
        count={count}
        page={page}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={handlePageChange}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="Model.name" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Model.fileFormat" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Model.fileSize" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Overview.actions" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {model.name}
                </TableCell>
                <TableCell align="right">{model.fileFormat}</TableCell>
                <TableCell align="right">{(model.fileSize / 1000000).toFixed(3)}</TableCell>
                <TableCell align="right">
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      component={Link}
                      color="primary"
                      aria-label="edit"
                      to={"/model/" + model.id}
                    >
                      <EditIcon />
                    </Button>
                    {(isAdmin || model.ownerId === userId) && <Button
                      component={Link}
                      onClick={() => handleOpen(model.id)}
                      color="error"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </Button>}
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
            {dialog}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
