import { useTheme } from "@emotion/react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Fab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../utils/lib";

export default function ImageView() {

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div>
      <img style={{ objectFit: 'contain', width: '100vw', maxHeight: '80vh', marginTop: '5vh' }} src={API_URL + location.state} />
      <Fab variant='outlined' sx={{ borderRadius: '100%', width: '50px', height: '50px', position: 'fixed', zIndex: 20, left: '20px', bottom: '20px' }}
        onClick={() => {
          navigate(-1);
        }}>
        <ArrowBackIcon sx={{ fontSize: '50px', color: theme.palette.primary.main }} />
      </Fab>
    </div>

  )
}