import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

export default function CustomDialog({
  isOpen,
  handleAccept,
  handleClose,
  title,
  content,
  maxWidth,
  hideConfirmation = false
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ height: '75vh' }}>
        {content}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          <FormattedMessage id="CustomDialog.no" />
        </Button>
        {!hideConfirmation && (
          <Button
            variant="contained"
            color="success"
            onClick={handleAccept}
            autoFocus
          >
            <FormattedMessage id="CustomDialog.yes" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
