import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
  csCZ,
  deDE,
  esES,
  frFR,
  itIT,
  plPL,
  ptBR
} from "@mui/x-data-grid";
import axios from "axios";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import ConfirmationList from "./ConfirmationList";
import CustomToolTip from "./CustomToolTip";
import useSnackbar from "./snackbar/useSnackBar";
import { API_URL } from "../utils/lib";

function CustomDataGrid() {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [localeObject, setLocaleObject] = useState({});
  const nextId = useRef(-1);
  const [publicRows, setPublicRows] = useState([]);
  const [publicRowModesModel, setPublicRowModesModel] = useState({});

  // state for merge-dialog
  const [isOpen, setIsOpen] = useState({ state: false, id: null, type: null });

  // snackbar for when deletion is not successful
  const { openSnackBar } = useSnackbar();

  const intl = useIntl();

  const navigate = useNavigate();

  const attributes = ['key', 'type'];
  const endpoint = '/attribute';

  // handles opening of merge-prompt
  const handleOpen = (id, type) => {
    setIsOpen({ state: true, id: id, type: type });
  };

  // handles closing of merge-prompt
  const handleClose = () => {
    setIsOpen({ state: false, id: null, type: null });
  };

  const handleMerge = (aid, bid) => {
    axios
      .put(`${API_URL}${endpoint}/merge/${aid}/${bid}`, undefined, { withCredentials: true })
      .then(response => {
        // remove attribute with id: bid
        setPublicRows(oldRows => oldRows.filter(r => r.id !== bid));
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  };

  const mergeDialog = useMemo(() => {
    if (!isOpen.state || !isOpen.type) {
      return;
    }
    const options = publicRows.filter(row => row.id >= 0 && row.id !== isOpen.id && row.type === isOpen.type);
    return <ConfirmationList
      options={options}
      title={intl.formatMessage({ id: "ConfirmationList.title" })}
      isOpen={isOpen.state}
      handleClose={handleClose}
      handleAccept={(value) => handleMerge(isOpen.id, value)}
      valueProp={options.length > 0 ? options[0].id : null}
    />;
  }, [isOpen, publicRows]);

  const handleMakePublic = (row) => {
    axios
      .put(`${API_URL}${endpoint}/${row.id}`, { ...row, publicFlag: true }, { withCredentials: true })
      .then(response => {
        // remove row from normal rows and append to public rows
        setRows(oldRows => oldRows.filter(r => r.id !== row.id));
        setPublicRows(oldRows => [...oldRows, response.data]);
      })
      .catch(error => {
        console.log(error);
      })
  };

  useEffect(() => {
    switch (intl.locale) {
      case 'de':
        setLocaleObject(deDE.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'fr':
        setLocaleObject(frFR.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'es':
        setLocaleObject(esES.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'it':
        setLocaleObject(itIT.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'cs':
        setLocaleObject(csCZ.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'pl':
        setLocaleObject(plPL.components.MuiDataGrid.defaultProps.localeText);
        break;
      case 'pt':
        setLocaleObject(ptBR.components.MuiDataGrid.defaultProps.localeText);
        break;
      default:
        setLocaleObject({});
    }
  }, [intl]);

  useEffect(() => {
    let active = true;
    axios
      .get(API_URL + '/attribute?published=true&usage=true', { withCredentials: true })
      .then(response => {
        if (!active) return;
        setPublicRows(response.data);
      })
      .catch(error => {
        console.log(error);
        navigate('/login');
      });
    axios
      .get(API_URL + '/attribute?published=false&usage=true', { withCredentials: true })
      .then(response => {
        if (!active) return;
        setRows(response.data);
      })
      .catch(error => {
        console.log(error);
        navigate('/login');
      })
    return () => {
      active = false;
    }
  }, []);

  const handleDelete = publicData => (row) => {
    if (row.id < 0) {
      if (publicData) {
        setPublicRows(oldRows => oldRows.filter(r => r.id !== row.id));
      } else {
        setRows(oldRows => oldRows.filter(r => r.id !== row.id));
      }
    } else {
      axios
        .delete(`${API_URL}${endpoint}/${row.id}`, { withCredentials: true })
        .then(response => {
          if (publicData) {
            setPublicRows(oldRows => oldRows.filter(r => r.id !== row.id));
          } else {
            setRows(oldRows => oldRows.filter(r => r.id !== row.id));
          }
        })
        .catch(error => {
          openSnackBar(intl.formatMessage({ id: "CustomDataGrid.deletionWarning" }));
          console.log(error);
        });
    }
  };

  const handleSaveClick = publicData => (id) => () => {
    if (publicData) {
      setPublicRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }));
    } else {
      setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }));
    }
  };

  const handleEditClick = publicData => (id) => () => {
    if (publicData) {
      setPublicRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }));
    } else {
      setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }));
    }
  };

  const handleDeleteClick = publicData => (id) => () => {
    if (publicData) {
      const row = publicRows.find((row) => row.id === id);
      if (row) {
        handleDelete(true)(row);
      }
    } else {
      const row = rows.find((row) => row.id === id);
      if (row) {
        handleDelete(false)(row);
      }
    }
  };

  const handleCancelClick = publicData => (id) => () => {
    if (id < 0) {
      if (publicData) {
        setPublicRows(rows => rows.filter(r => r.id !== id));
      } else {
        setRows(rows => rows.filter(r => r.id !== id));
      }
      return;
    }
    if (publicData) {
      setPublicRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }));
    } else {
      setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } }));
    }
  };

  const getNextId = () => {
    return nextId.current--;
  }

  const processRowUpdate = useCallback(publicData => async (newRow, oldRow) => {
    const putObject = {};
    attributes.forEach(attribute => {
      putObject[attribute] = newRow[attribute];
    });
    putObject.publicFlag = newRow.publicFlag;
    if (newRow.id < 0) {
      return await axios
        .post(API_URL + endpoint, putObject, { withCredentials: true })
        .then(function (response) {
          let value = response.data;
          const updateRow = { ...value };
          if (publicData) {
            setPublicRows((oldRows) => {
              return [...oldRows.filter(r => r.id !== newRow.id), updateRow];
            });
            setPublicRowModesModel((oldModel) => ({
              ...oldModel, [updateRow.id]: { mode: GridRowModes.Edit, fieldToFocus: attributes[0] }
            }));
          } else {
            setRows((oldRows) => {
              return [...oldRows.filter(r => r.id !== newRow.id), updateRow];
            });
            setRowModesModel((oldModel) => ({
              ...oldModel, [updateRow.id]: { mode: GridRowModes.Edit, fieldToFocus: attributes[0] }
            }));
          }
          return updateRow;
        })
        .catch(function (error) {
          console.log(error);
          alert(intl.formatMessage({ id: "CustomDataGrid.saveWarning" }));
          return oldRow;
        });
    } else {
      return await axios
        .put(API_URL + endpoint + `/${newRow.id}`, putObject, { withCredentials: true })
        .then(function (response) {
          let value = response.data;
          const updateRow = { ...value };
          if (publicData) {
            setPublicRows((oldRows) => {
              oldRows = oldRows.filter(row => row.id !== newRow.id);
              return [...oldRows, updateRow];
            });
          } else {
            setRows((oldRows) => {
              oldRows = oldRows.filter(row => row.id !== newRow.id);
              return [...oldRows, updateRow];
            });
          }
          return updateRow;
        })
        .catch(function (error) {
          console.log(error);
          alert(intl.formatMessage({ id: "CustomDataGrid.saveWarning" }));
          return oldRow;
        });
    }
  }, [rows, setRows, publicRows, setPublicRows]);

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log(error);
    openSnackBar(error);
  }, []);

  const handleRowModesModelChange = publicData => (newRowModesModel) => {
    if (publicData) {
      setPublicRowModesModel(newRowModesModel);
    } else {
      setRowModesModel(newRowModesModel);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const columns = [];
  const publicColumns = [];
  const standardColumns = [
    { field: 'key', headerName: intl.formatMessage({ id: 'CategoryOverview.key' }), maxWidth: 180, flex: 1, editable: true },
    {
      field: 'type',
      headerName: intl.formatMessage({ id: "AttributeBox.type" }),
      maxWidth: 180,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'PLAIN', label: intl.formatMessage({ id: 'AttributeBox.noValue' }) },
        { value: 'BOOL', label: intl.formatMessage({ id: 'AttributeBox.bool' }) },
        { value: 'NUMBER', label: intl.formatMessage({ id: 'AttributeBox.number' }) },
        { value: 'TEXT', label: intl.formatMessage({ id: 'AttributeBox.text' }) }
      ]
    },
    { field: 'usage', headerName: "Nutzung", maxWidth: 180, flex: 1, editable: false },
  ];
  columns.push(...standardColumns);
  publicColumns.push(...standardColumns);

  columns.push(
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "Overview.actions" }),
      maxWidth: 180,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(false)(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(false)(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(false)(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(false)(id)}
            color="inherit"
          />,
          <CustomToolTip
            tooltip={<FormattedMessage id="Attributes.makePublic" />}
            placement={"right"}
          >
            <GridActionsCellItem
              icon={<PublicIcon />}
              label="Make Public"
              onClick={() => handleMakePublic(row)}
              color="inherit"
            />
          </CustomToolTip>
        ];
      },
    },
  );

  publicColumns.push(
    {
      field: "actions",
      type: "actions",
      headerName: intl.formatMessage({ id: "Overview.actions" }),
      maxWidth: 180,
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = publicRowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(true)(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(true)(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(true)(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(true)(id)}
            color="inherit"
          />,
          <Tooltip title={<Typography variant="subtitle2"><FormattedMessage id="Attributes.merge" /></Typography>} placement="right" sx={{ fontSize: 200 }}>
            <GridActionsCellItem
              icon={<MergeTypeIcon />}
              label="Merge"
              onClick={() => handleOpen(id, row.type)}
              color="inherit"
            />
          </Tooltip>
        ];
      },
    },
  );

  function AddToolbar(props) {
    const { setRows, setRowModesModel, publicData } = props;

    const handleClick = () => {
      const postObject = {};
      attributes.forEach(attribute => {
        postObject[attribute] = '';
      });
      postObject.type = 'PLAIN';
      if (publicData) {
        postObject.publicFlag = true;
      } else {
        postObject.publicFlag = false;
      }
      postObject.id = getNextId();
      setRows((oldRows) => {
        return [...oldRows, postObject]
      });
      setRowModesModel((oldModel) => ({
        ...oldModel, [postObject.id]: { mode: GridRowModes.Edit, fieldToFocus: attributes[0] },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          <FormattedMessage id="CustomDataGrid.addEntry" />
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" gutterBottom sx={{ marginLeft: 1 }} >
        <FormattedMessage id="Attributes.public" />
      </Typography>
      <DataGrid
        localeText={localeObject}
        rows={publicRows}
        columns={publicColumns}
        editMode="row"
        rowModesModel={publicRowModesModel}
        onRowModesModelChange={handleRowModesModelChange(true)}
        onRowEditStop={handleRowEditStop}
        sx={{
          m: 1
        }}
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(true)(updatedRow, originalRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        slots={{
          toolbar: AddToolbar,
          noRowsOverlay: () => <div></div>
        }}
        slotProps={{
          toolbar: { setRows: setPublicRows, setRowModesModel: setPublicRowModesModel, publicData: true },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
      <Typography variant="h5" gutterBottom sx={{ marginLeft: 1, marginTop: 3 }} >
        <FormattedMessage id="Attributes.private" />
      </Typography>
      <DataGrid
        localeText={localeObject}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange(false)}
        onRowEditStop={handleRowEditStop}
        sx={{
          m: 1
        }}
        processRowUpdate={(updatedRow, originalRow) =>
          processRowUpdate(false)(updatedRow, originalRow)
        }
        onProcessRowUpdateError={handleProcessRowUpdateError}
        slots={{
          toolbar: AddToolbar,
          noRowsOverlay: () => <div></div>
        }}
        slotProps={{
          toolbar: { setRows: setRows, setRowModesModel, publicData: false },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[25, 50, 100]}
      />
      {mergeDialog}
    </Box>
  );
}

export default memo(CustomDataGrid);