import Cookies from "js-cookie";

export const clearAuthentication = () => {
  Cookies.remove('accessToken');
}

export function jsonBlob(obj) {
  return new Blob([JSON.stringify(obj)], {
    type: "application/json",
  });
}

export const getAccessToken = () => Cookies.get('accessToken');
export const isAuthenticated = () => !!getAccessToken();

export const getFileExtension = (fileName => {
  return fileName.split('.').pop().toLowerCase();
});

export const API_URL = "/imsFilesApi";