const mapTypes = [
  { type: "map", three: "map", name: "Map" },
  { type: "normal_map", three: "normalMap", name: "Normal" },
  { type: "roughness_map", three: "roughnessMap", name: "Roughness" },
  { type: "metalness_map", three: "metalnessMap", name: "Metalness" },
  { type: "bump_map", three: "bumpMap", name: "Bump" },
  { type: "displacement_map", three: "displacementMap", name: "Displacement" },
  { type: "ao_map", three: "aoMap", name: "Ambient occlusion" }
];

export default mapTypes;
