import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Slider, Stack, Switch, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js';
import ComboBox from "../components/ComboBox";
import CustomDialog from "../components/CustomDialog";
import useSnackbar from "../components/snackbar/useSnackBar";
import ZoomSlider from "../components/ZoomSlider";
import DataTable from "../data/DataTable";
import { API_URL } from "../utils/lib";
import ManualModelPreview from "./ManualModelPreview";
import ModelPreview from "./ModelPreview";
import Upload from "./Upload";

export default function EditModel({ isAdmin, userId }) {
  const { pid, id } = useParams();

  const navigate = useNavigate();

  const [model, setModel] = useState({
    name: "",
    length: 100,
    height: 100,
    depth: 100,
    scale: 1.0,
    publicFlag: false,
    object: {
      id: undefined,
      name: ''
    }
  });

  const [renderer, setRenderer] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [scene, setScene] = useState(null);

  const isStatic = id !== 'new' && !isAdmin && model.ownerId !== userId;

  let fetchPrefix = '';
  if (pid) {
    fetchPrefix += `/product/${pid}`;
  }

  // these states hold the file selected by the user, unused when data is fetched from the server
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2d, setSelectedFile2d] = useState(null);
  const [selectedPreviewPicture, setSelectedPreviewPicture] = useState(null);

  // the urls to the resource on the server
  const [url3d, setURL3d] = useState("");
  const [url2d, setURL2d] = useState("");
  const [urlPreview, setURLPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // this key is used to trigger an update of the ModelPreview-component when the resource behind the link reloads
  const [key, setKey] = useState(0);

  // these states indicate to the submit-function if files should be deleted on the server
  const [delete3d, setDelete3d] = useState(false);
  const [delete2d, setDelete2d] = useState(false);
  const [deletePreviewPicture, setDeletePreviewPicture] = useState(false);

  // the states for the file-drawers
  const [open2dFileChooser, setOpen2dFileChooser] = useState(false);
  const [openPreviewPictureChooser, setOpenPreviewPictureChooser] = useState(false);

  const [toggleOption, setToggleOption] = useState('3d');
  const [manual3d, setManual3d] = useState(false);
  const [manualObject, setManualObject] = useState({ length: 1, height: 1, depth: 1 });
  const [profMode, setProfMode] = useState(false);
  const modelRef = {
    meshRef: useRef(),
    controlRef: useRef(),
    gridRef: useRef()
  }

  // the state for the zoom
  const [zoom, setZoom] = useState(10.0)

  const { openSnackBar, closeSnackBar } = useSnackbar();

  const exporter = new GLTFExporter();

  const intl = useIntl();

  const handleSelectModel = (file) => {
    if (file) {
      const url = URL.createObjectURL(file);
      setModel(oldModel => ({ ...oldModel, scale: 1.0 }));
      setURL3d(url);
      setDelete3d(false);
      file.uploaded = false;
    } else {
      setURL3d("");
      setDelete3d(true);
    }
    setSelectedFile(file);
  };

  const handleSelectFile2d = (data) => () => {
    setURL2d(`${API_URL}/data/${data.id}/file`);
    setSelectedFile2d({ ...data, uploaded: false });
    setOpen2dFileChooser(false);
  };

  const handleSelectPreviewPicture = (data) => () => {
    setURLPreview(`${API_URL}/data/${data.id}/file`);
    setSelectedPreviewPicture({ ...data, uploaded: false });
    setOpenPreviewPictureChooser(false);
  };

  const handleDeleteFile2d = () => {
    setURL2d("");
    setSelectedFile2d(null);
    setDelete2d(true);
  };

  const handleDeletePreviewPicture = () => {
    setURLPreview("");
    setSelectedPreviewPicture(null);
    setDeletePreviewPicture(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setModel({
      ...model,
      [name]: value,
    });
  };

  const handleInputChangeObject = (e) => {
    const { name, value } = e.target;

    setManualObject(oldObject => ({
      ...oldObject,
      [name]: value,
    }));
  };

  function jsonBlob(obj) {
    return new Blob([JSON.stringify(obj)], {
      type: "application/json",
    });
  }

  /**
   * Should be executed when data from the server is fetched.
   * @param {*} data the body of the server-response
   */
  const loadDone = (data) => {
    setModel(data);
    setManual3d(false);
    if (data.filePath) {
      setURL3d(API_URL + fetchPrefix + `/model/${id}/file?type=3d&key=${key}`);
      setSelectedFile({ name: data.filename, uploaded: true });
      // trigger re-render of ModelPreview
      setKey(oldKey => oldKey + 1);
    } else {
      setURL3d("");
    }
    if (data.file2d) {
      setURL2d(API_URL + fetchPrefix + `/model/${id}/file?type=2d`);
      setSelectedFile2d({ name: data.file2d.fileName, uploaded: true });
    } else {
      setURL2d("");
    }
    if (data.previewPicture) {
      setURLPreview(API_URL + fetchPrefix + `/model/${id}/previewPicture?quality=high`);
      setSelectedPreviewPicture({ name: data.previewPicture.fileName, uploaded: true });
    } else {
      setURLPreview("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (manual3d) {
      createManualModel();
    } else {
      submitData(selectedFile);
    }
  };

  const submitData = (file3d) => {
    // hold promises that fulfill when the deletion-request has finished (rejected or resolved)
    let deletion3d = () => new Promise(resolve => resolve());
    let deletion2d = () => new Promise(resolve => resolve());
    let deletionPreview = () => new Promise(resolve => resolve());
    // always check if a file should be deleted (the original response from the server must contain an already set file)
    if (delete3d && model.filePath) {
      deletion3d = () => axios
        .delete(`${API_URL}/model/${id}/file?type=3d`, { withCredentials: true })
        .catch(error => {
          console.log(error);
        });
    }
    if (delete2d && model.file2d) {
      deletion2d = () => axios
        .delete(`${API_URL}/model/${id}/file?type=2d`, { withCredentials: true })
        .catch(error => {
          console.log(error);
        });
    }
    if (deletePreviewPicture && model.previewPicture) {
      deletionPreview = () => axios
        .delete(`${API_URL}/model/${id}/previewPicture`, { withCredentials: true })
        .catch(error => {
          console.log(error);
        });
    }

    const modelObj = { ...model };
    if (modelObj.object && !modelObj.object.id) {
      modelObj.object = null;
    }

    const formData = new FormData();
    if (file3d && !file3d.uploaded) {
      formData.append("file3d", file3d, file3d.name);
    }

    if (selectedFile2d && !selectedFile2d.uploaded) {
      modelObj.file2d = { ...selectedFile2d };
    } else {
      modelObj.file2d = null;
    }
    if (selectedPreviewPicture && !selectedPreviewPicture.uploaded) {
      modelObj.previewPicture = { ...selectedPreviewPicture };
    } else {
      modelObj.previewPicture = null;
    }
    // promise for taking screenshot of preview-picture
    let previewPromise = new Promise(resolve => resolve());
    if (file3d && !selectedPreviewPicture) {
      // if there is a 3d-file set, but not a previewPicture, make a screenshot and set the previewPicture
      previewPromise = takeScreenshot();
    }

    formData.append("model", jsonBlob(modelObj));

    setIsLoading(true);
    if (id === "new") {
      previewPromise
        .then(previewPicture => {
          if (previewPicture) {
            formData.append("preview_picture", previewPicture, "screenshot.jpg")
          }
        })
        .then(() => axios.post(`${API_URL}/model/`, formData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true
        }))
        .then((response) => {
          // handle the response
          setSelectedFile(null);
          setSelectedFile2d(null);
          setSelectedPreviewPicture(null);
          navigate('/models');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // wait until every deletion has finished before making PUT-request
      deletion3d()
        .then(() => deletion2d())
        .then(() => deletionPreview())
        .then(() => previewPromise)
        .then(previewPicture => {
          if (previewPicture) {
            formData.append("preview_picture", previewPicture, "screenshot.jpg")
          }
        })
        .then(() => axios
          .put(`${API_URL}/model/${id}`, formData, {
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true
          }))
        .then((response) => {
          // handle the response
          navigate('/models');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  useEffect(() => {
    if (id !== "new") {
      let fetchURL = '';
      if (pid) {
        fetchURL = `/product/${pid}`;
      }
      fetchURL += `/model/${id}`
      setIsLoading(true);
      axios
        .get(API_URL + fetchURL, { withCredentials: true })
        .then(response => {
          loadDone(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }, [id]);

  const handleToggleChange = (event, newToggleOption) => {
    if (newToggleOption !== null) {
      setToggleOption(newToggleOption);
    }
  }

  const handleManualToggle = (event) => {
    if (event.target.checked && selectedFile) {
      openSnackBar(intl.formatMessage({ id: "EditModel.manualWarning" }));
      return;
    }
    if (manual3d && !event.target.checked) {
      // reset the selected file
      setURL3d(null);
      setSelectedFile(null);
    } else {
      setModel(oldModel => ({ ...oldModel, scale: 1.0, ...manualObject }));
    }
    setManual3d(event.target.checked);
  };

  const createManualModel = () => {
    exporter.parse(modelRef.meshRef.current, gltf => {
      const output = JSON.stringify(gltf, null, 2);
      const file = new Blob([output], { type: "application/json" });
      file.name = 'custom_object.glb';
      const url = URL.createObjectURL(file);
      setURL3d(url);
      file.uploaded = false;
      setSelectedFile(file);
      submitData(file);
    }, (error) => console.log(error), { onlyVisible: true });
  };

  const handleManualValueChange = (type) => (event, newValue) => {
    setManualObject(old => ({ ...old, [type]: newValue }));
    setModel(old => ({ ...old, [type]: parseInt(100 * newValue, 10) }))
  };

  /**
   * Adjust the scale of the model according to the maximum x-z-coordinate.
   * @param {*} maximum 
   */
  const adjustScale = (maximum) => {
    if (model.scale === 1.0) {
      setModel(oldModel => ({ ...oldModel, scale: Number((10.0 / maximum).toFixed(3)) }));
    }
  }

  /**
   * Takes a screenshot of the current 3d-model and downloads it.
   * @returns 
   */
  const takeScreenshot = () => new Promise(resolve => {
    if (!renderer) {
      resolve(null);
      return;
    }
    if (modelRef.controlRef.current) {
      modelRef.controlRef.current.reset();
    }
    if (scene) {
      // remove grid
      scene.remove(modelRef.gridRef.current);
    }
    if (bounds) {
      // refresh bounds
      bounds.refresh().clip().fit();
    }
    // make background white
    renderer.setClearColor(0xffffff, 1);
    setTimeout(() => {
      // make a blob and resolve the promise
      renderer.domElement.toBlob((previewPicture => {
        resolve(previewPicture);
        // reset the scene and 3d model
        if (scene) {
          // add grid again
          scene.add(modelRef.gridRef.current);
        }
        // make background clear
        renderer.setClearColor(0x000000, 0);
      }), 'image/jpeg');
    }, 500)
  });

  const textFieldStyle = {
    width: '90%'
  }

  const zoomSlider = useMemo(() => {
    return <div style={{
      backgroundColor: 'white',
      paddingLeft: '4px',
      paddingRight: '4px',
      height: '80%',
      position: 'absolute',
      left: '10px',
      bottom: '10%',
      zIndex: 20,
      borderRadius: 30,
      border: '1px solid'
    }}>
      <ZoomSlider zoom={zoom} setZoom={setZoom} />
    </div>
  });

  const handlePublicChange = (event) => {
    setModel(oldModel => ({ ...oldModel, publicFlag: event.target.checked }));
  };

  const previewElement = useMemo(() => {
    if (toggleOption === '3d') {
      if (manual3d) {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {zoomSlider}
            <ManualModelPreview model={manualObject} scale={1.0} ref={modelRef.meshRef} zoomValue={zoom} />
          </div>
        )
      } else {
        return (
          <div style={{ width: '100%', height: '100%' }}>
            {zoomSlider}
            <ModelPreview url={url3d} data={model} fileName={selectedFile?.name || ''} adjustScale={adjustScale} zoomValue={zoom} setScene={setScene} setGL={setRenderer} setBounds={setBounds} ref={modelRef} />
          </div>
        )
      }

    } else if (toggleOption === '2d') {
      return (
        <img height='100%' src={url2d} alt={url2d} style={{ maxWidth: '100%' }} />
      )
    } else if (toggleOption === 'preview') {
      return (
        <img height='100%' src={urlPreview} alt={urlPreview} style={{ maxWidth: '100%' }} />
      )
    }
    return;
  }, [toggleOption, url3d, url2d, urlPreview, model, selectedFile, manual3d, manualObject, zoom]);

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        <FormattedMessage id="EditModel.heading" />
      </Typography>
      <CustomDialog
        title={intl.formatMessage({ id: "EditModel.2dModel" })}
        content={<DataTable selectable={true} pdf={false} svg={true} image={false} onSelect={handleSelectFile2d} />}
        isOpen={open2dFileChooser}
        handleAccept={() => setOpen2dFileChooser(false)}
        handleClose={() => setOpen2dFileChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <CustomDialog
        title={intl.formatMessage({ id: "EditModel.chooseThumbnail" })}
        content={<DataTable selectable={true} pdf={false} svg={false} image={true} onSelect={handleSelectPreviewPicture} />}
        isOpen={openPreviewPictureChooser}
        handleAccept={() => setOpenPreviewPictureChooser(false)}
        handleClose={() => setOpenPreviewPictureChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container alignItems='center' sx={{ marginBottom: 6, width: '100%' }}>
          <Grid item xs={12} >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  height: 600,
                  width: '95%',
                  borderRadius: 3,
                  marginTop: '10px',
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid',
                  padding: '4px'
                }}
                style={{ position: 'relative' }}
              >
                {previewElement}
              </Box>
              {toggleOption === '3d' && !manual3d && <Box sx={{
                width: {
                  xs: '90vw',
                  sm: '60vw',
                }
              }}>
                <FormControlLabel control={<Switch
                  checked={profMode}
                  onChange={event => setProfMode(event.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />}
                  label={<p style={{ width: '100px' }} ><b>{intl.formatMessage({ id: "Material.profSettingsLabel" })}</b></p>}
                  labelPlacement="start"
                  sx={{ margin: 0 }} />
                {profMode && <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                  <p style={{ width: '100px', textAlign: '' }}><FormattedMessage id="EditModel.scale" /></p>
                  <Slider aria-label="ModelScale" value={model.scale} onChange={(event, newValue) => setModel(old => ({ ...old, scale: newValue }))}
                    min={0.1}
                    max={50}
                    shiftStep={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                    style={{ width: '100%' }}
                  />
                </Stack>}
              </Box>}
              {toggleOption === '3d' && manual3d && <Box sx={{
                width: {
                  xs: '90vw',
                  sm: '60vw'
                }
              }} >
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                  <p style={{ width: '100px' }}><FormattedMessage id="EditModel.length" /></p>
                  <Slider aria-label="ManualModelLength" value={manualObject.length} onChange={handleManualValueChange('length')}
                    min={0.1}
                    max={20}
                    shiftStep={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                    style={{ width: '100%' }}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    name={"length"}
                    label={intl.formatMessage({ id: "EditModel.manualLength" })}
                    type="number"
                    inputProps={{ min: 0.1, step: 0.01 }}
                    value={manualObject.length}
                    onChange={(event) => {
                      handleInputChangeObject(event);
                    }}
                    sx={{ width: '200px' }}
                  />
                </Stack>
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }} >
                  <p style={{ width: '100px' }}><FormattedMessage id="EditModel.height" /></p>
                  <Slider aria-label="ManualModelHeight" value={manualObject.height} onChange={(event, newValue) => setManualObject(old => ({ ...old, height: newValue }))}
                    min={0.1}
                    max={20}
                    shiftStep={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                    style={{ width: '100%' }}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    name={"height"}
                    label={intl.formatMessage({ id: "EditModel.manualHeight" })}
                    type="number"
                    inputProps={{ min: 0.1, step: 0.01 }}
                    value={manualObject.height}
                    onChange={(event) => {
                      handleInputChangeObject(event);
                    }}
                    sx={{ width: '200px' }}
                  />
                </Stack>
                <Stack spacing={2} direction="row" sx={{ alignItems: 'center', mb: 1 }}>
                  <p style={{ width: '100px' }}><FormattedMessage id="EditModel.depth" /></p>
                  <Slider aria-label="ManualModelDepth" value={manualObject.depth} onChange={(event, newValue) => setManualObject(old => ({ ...old, depth: newValue }))}
                    min={0.1}
                    max={20}
                    shiftStep={1}
                    step={0.01}
                    valueLabelDisplay="auto"
                    style={{ width: '100%' }}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    name={"depth"}
                    label={intl.formatMessage({ id: "EditModel.manualDepth" })}
                    type="number"
                    inputProps={{ min: 0.1, step: 0.01 }}
                    value={manualObject.depth}
                    onChange={(event) => {
                      handleInputChangeObject(event);
                    }}
                    sx={{ width: '200px' }}
                  />
                </Stack>
              </Box>}

              <ToggleButtonGroup
                color="primary"
                value={toggleOption}
                exclusive
                onChange={handleToggleChange}
                aria-label="Platform">
                <ToggleButton value="3d">3D</ToggleButton>
                <ToggleButton value="2d">2D</ToggleButton>
                <ToggleButton value="preview"><FormattedMessage id="EditModel.thumbnail" /></ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, width: "100%", paddingBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"name"}
              label={intl.formatMessage({ id: "EditModel.name" })}
              type="text"
              value={model.name}
              onChange={(event) => {
                handleInputChange(event);
              }}
              sx={textFieldStyle}
              disabled={isStatic}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              name={"length"}
              label={intl.formatMessage({ id: "EditModel.length" })}
              type="number"
              value={model.length}
              onChange={(event) => {
                handleInputChange(event);
              }}
              sx={textFieldStyle}
              disabled={isStatic || manual3d}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              name={"height"}
              label={intl.formatMessage({ id: "EditModel.height" })}
              type="number"
              value={model.height}
              onChange={(event) => {
                handleInputChange(event);
              }}
              sx={textFieldStyle}
              disabled={isStatic || manual3d}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <TextField
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
              name={"depth"}
              label={intl.formatMessage({ id: "EditModel.depth" })}
              type="number"
              value={model.depth}
              onChange={(event) => {
                handleInputChange(event);
              }}
              sx={textFieldStyle}
              disabled={isStatic || manual3d}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} >
            <ComboBox
              label={intl.formatMessage({ id: "EditModel.objectType" })}
              selection={model.object}
              onSelect={(modelType => {
                setModel(existingValues => ({
                  ...existingValues,
                  object: modelType
                }))
              })}
              required={false}
              url={`/object/all`}
              style={textFieldStyle}
              idTag="object"
              disabled={isStatic}
            />
          </Grid>
          {isAdmin && <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel control={<Checkbox checked={model.publicFlag} onChange={handlePublicChange} />} label={intl.formatMessage({ id: "Edit.published" })} />
          </Grid>}
          {!isStatic && <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={manual3d}
                    onChange={handleManualToggle}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={intl.formatMessage({ id: "EditModel.manualModel" })} />
            </FormGroup>
          </Grid>}

          {
            !isStatic && !manual3d && (
              <Grid item xs={12}>
                <Upload
                  id={id}
                  selectedFile={selectedFile}
                  onChange={handleSelectModel}
                  label={intl.formatMessage({ id: "EditModel.3dModel" })}
                  accept={".fbx, .glb, .obj, .zip"}
                  style={{ width: '30vw' }}
                />
              </Grid>
            )
          }
          {!isStatic && <Grid item xs={12}>
            <Button sx={{ width: '30vw' }} variant="outlined" onClick={() => setOpen2dFileChooser(true)} >{intl.formatMessage({ id: "EditModel.2dModel" })} (SVG)</Button>
            {selectedFile2d && (
              <IconButton aria-label="clear" color="error" onClick={handleDeleteFile2d}>
                <ClearIcon />
              </IconButton>
            )}
          </Grid>}
          {!isStatic && <Grid item xs={12}>
            <Button sx={{ width: '30vw' }} variant="outlined" onClick={() => setOpenPreviewPictureChooser(true)} >{intl.formatMessage({ id: "EditModel.chooseThumbnail" })} (JPG / PNG)</Button>
            {selectedPreviewPicture && (
              <IconButton aria-label="clear" color="error" onClick={handleDeletePreviewPicture}>
                <ClearIcon />
              </IconButton>
            )}
          </Grid>}
        </Grid>

        {!isStatic && <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>}
      </Box>
    </Container>
  );
}
