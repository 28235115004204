import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  ButtonGroup,
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SearchField from "../components/SearchField";
import useSnackbar from "../components/snackbar/useSnackBar";
import { API_URL } from "../utils/lib";

export default function Object({ isAdmin, userId }) {
  const [objects, setObjects] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  // number of pages
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState("");

  const [isOpen, setIsOpen] = useState({ state: false, id: null });

  const intl = useIntl();

  const navigate = useNavigate();

  useEffect(() => {
    let fetchURL = `/object?page=${page - 1}&size=${pageSize}`;
    if (query) {
      fetchURL += `&search=${query}`;
    }
    axios
      .get(API_URL + fetchURL, { withCredentials: true })
      .then(response => {
        setObjects(response.data.content);
        setCount(response.data.totalPages);
      })
      .catch(error => {
        console.log(error);
        navigate('/login');
      });
  }, [page, pageSize, query]);

  const handleSearch = (value) => {
    setPage(1);
    setQuery(value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // open deletion-dialog
  const handleOpen = (id) => {
    setIsOpen({ state: true, id: id });
  };

  // close deletion-dialog
  const handleClose = () => {
    setIsOpen({ state: false, id: null });
  };

  // snackbar for when deletion is not successful
  const { openSnackBar, closeSnackBar } = useSnackbar();

  function handleDelete(id) {
    axios
      .delete(`${API_URL}/object/${id}`, { withCredentials: true })
      .then((response) => {
        // handle the response
        handleClose();
        setObjects(oldObjects => oldObjects.filter(i => i.id !== id));
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        handleClose();
        openSnackBar(intl.formatMessage({ id: "Overview.deletionFailure" }));
      });
  }

  // dialog that prompts deletion
  const dialog = useMemo(() => {
    let d = (
      <ConfirmationDialog
        title={intl.formatMessage({ id: "Object.deletionQuestion" })}
        content={intl.formatMessage({ id: "Overview.deletionInfo" })}
        isOpen={isOpen.state}
        handleAccept={() => handleDelete(isOpen.id)}
        handleClose={handleClose}
      />
    );
    if (isOpen.state) {
      return d;
    }
  }, [isOpen]);

  const objectList = objects.map((object, idx) => {
    return (
      <TableRow
        key={idx}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {object.name}
        </TableCell>
        <TableCell align="right">
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              component={Link}
              color="primary"
              aria-label="edit"
              to={"/object/" + object.id}
            >
              <EditIcon />
            </Button>
            {(isAdmin || object.ownerId === userId) && <Button
              component={Link}
              onClick={() => handleOpen(object.id)}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </Button>}
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Container>
      <Button
        variant="contained"
        color="success"
        component={Link}
        to="/object/new"
        sx={{ float: "right" }}
      >
        <FormattedMessage id="Overview.create" />
      </Button>
      <h3><FormattedMessage id="Object.heading" /></h3>
      <SearchField onChange={(value) => handleSearch(value)} />
      <Pagination
        size="large"
        count={count}
        page={page}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={handlePageChange}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="Object.name" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Overview.actions" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{objectList}</TableBody>
        </Table>
      </TableContainer>
      {dialog}
    </Container>
  );
}
