import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { useState } from 'react';

export default function CustomSpeedDial({ actions }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();

  return (
    <Box sx={{ height: 70, transform: 'translateZ(0px)', flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{
          position: 'absolute', bottom: 8, right: 16, "& .MuiSpeedDialAction-fab": {
            color: theme.palette.primary.main,
            borderWidth: '1px',
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid'
          }, "& .MuiSpeedDialAction-staticTooltipLabel": {
            color: theme.palette.primary.main,
            borderWidth: '1px',
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid'
          }
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => {
              action.func();
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}