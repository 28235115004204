import { Box, Button, CircularProgress, Container, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../utils/lib";

export default function EditManufacturer() {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (id === "new") {
      axios
        .post(`${API_URL}/manufacturer`, data, { withCredentials: true })
        .then((response) => {
          navigate('/manufacturers');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`${API_URL}/manufacturer/${id}`, data, { withCredentials: true })
        .then((response) => {
          navigate('/manufacturers')
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      axios
        .get(`${API_URL}/manufacturer/${id}`, { withCredentials: true })
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        <FormattedMessage id="EditManufacturer.heading" />
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, margin: 0, width: "100%", paddingBottom: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"name"}
              label={intl.formatMessage({ id: "EditManufacturer.name" })}
              type="text"
              value={data.name || ''}
              onChange={(event) => setData(oldData => ({ ...oldData, name: event.target.value }))}
            />
          </Grid>

        </Grid>
        <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>
      </Box>
    </Container>
  );
}
