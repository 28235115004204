import { Bounds, OrbitControls, PerspectiveCamera, useBounds, useFBX, useGLTF } from "@react-three/drei";
import { Canvas, useLoader, useThree } from "@react-three/fiber";
import JSZip from "jszip";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { FormattedMessage } from "react-intl";
import { Box3, Vector3 } from "three";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";

const ModelPreview = forwardRef(({ url, data, fileName, adjustScale, zoomValue, setGL, setBounds, setScene }, ref) => {

  const { controlRef, gridRef } = ref;

  const cameraRef = useRef();

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(...zoom(zoomValue, new Vector3(1.0, 1.5, 2.0)));
    }
  }, []);

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(...zoom(zoomValue, cameraRef.current.position));
    }
  }, [zoomValue]);

  const zoom = (value, pos) => {
    pos = pos.normalize();
    return [pos.x * value, pos.y * value, pos.z * value]
  };

  const modelElement = useMemo(() => {
    if (/\.fbx$/i.test(fileName)) {
      return <ModelFBX url={url} data={data} adjustScale={adjustScale} setGL={setGL} setBounds={setBounds} setScene={setScene} ref={ref} />;
    } else if (/\.glb$/i.test(fileName)) {
      return <ModelGLTF url={url} data={data} adjustScale={adjustScale} setGL={setGL} setBounds={setBounds} setScene={setScene} ref={ref} />;
    } else if (/\.obj$/i.test(fileName)) {
      return <ModelOBJ url={url} data={data} adjustScale={adjustScale} setGL={setGL} setBounds={setBounds} setScene={setScene} ref={ref} />;
    } else if (/\.zip/i.test(fileName)) {
      // unzip file
      return <ModelOBJZip url={url} data={data} adjustScale={adjustScale} setGL={setGL} setBounds={setBounds} setScene={setScene} ref={ref} />
    } else {
      return null;
    }
  }, [url, data, fileName])

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Canvas
        color={0xffffff}
        linear
        gl={{ preserveDrawingBuffer: true }}
      >
        <ambientLight color={0xffffff} intensity={0.66} />
        <pointLight
          position={[0.0, 1.2, -1.2]}
          intensity={1}
          distance={10}
          decay={2}
          power={5}
          castShadow={true}
        />

        {url && (
          <Bounds fit clip observe damping={6} maxDuration={0} margin={1.2} >
            {modelElement}
          </Bounds>
        )}

        <PerspectiveCamera makeDefault ref={cameraRef} onUpdate={(self) => self.position.set(...zoom(zoomValue, new Vector3(1.0, 1.5, 2.0)))} />
        <OrbitControls makeDefault enablePan={false} ref={controlRef} />
        <gridHelper ref={gridRef} args={[50, 50]} />
      </Canvas>
    </ErrorBoundary>
  );
});

function ErrorComponent({ error, resetErrorBoundary }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <h2><FormattedMessage id="Model.errorMessage" /></h2>
      <button onClick={resetErrorBoundary}><FormattedMessage id="Model.retryButton" /></button>
    </div>
  );
}

const ModelGLTF = forwardRef(({ url, data, adjustScale, setGL, setBounds, setScene }, ref) => {
  const { model } = useGLTF(url);

  const { meshRef } = ref;
  const { gl, scene } = useThree();
  const bounds = useBounds();
  useEffect(() => {
    setGL(gl);
  }, [gl]);

  useEffect(() => {
    setBounds(bounds);
  }, [bounds]);

  useEffect(() => {
    setScene(scene);
  }, [scene]);

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    // position the bottom of the model on the x-z-plane
    model.position.y -= box3.min.y
  }, [url, data]);

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    const size = new Vector3();
    box3.getSize(size);
    const maximum = Math.max(size.x, size.z);
    if (maximum > 50) {
      // adjust the scale
      adjustScale(maximum);
    }
  }, [url]);

  // useFrame((state, delta) => {
  //   modelRef.current.rotation.y += delta / 5;
  // });

  return (
    <primitive
      ref={meshRef}
      object={model}
      scale={(data.scale === undefined || data.scale === 0) ? 1.0 : data.scale}
    />
  );
});

const ModelFBX = forwardRef(({ url, data, adjustScale, setGL, setBounds, setScene }, ref) => {
  const model = useFBX(url);

  const { meshRef } = ref;
  const { gl, scene } = useThree();
  const bounds = useBounds();

  useEffect(() => {
    setGL(gl);
  }, [gl]);

  useEffect(() => {
    setBounds(bounds);
  }, [bounds]);

  useEffect(() => {
    setScene(scene);
  }, [scene]);

  // const modelRef = useRef();

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    // position the bottom of the model on the x-z-plane
    model.position.y -= box3.min.y
  }, [url, data]);

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    const size = new Vector3();
    box3.getSize(size);
    const maximum = Math.max(size.x, size.z);
    if (maximum > 50) {
      // adjust the scale
      adjustScale(maximum);
    }
  }, [url]);

  // useFrame((state, delta) => {
  //   modelRef.current.rotation.y += delta / 5;
  // });

  return (
    <primitive
      ref={meshRef}
      object={model}
      scale={(data.scale === undefined || data.scale === 0) ? 1.0 : data.scale}
    />
  );
});

const ModelOBJ = forwardRef(({ url, data, adjustScale, setGL, setBounds, setScene }, ref) => {
  const model = useLoader(OBJLoader, url);

  const { meshRef } = ref;
  const { gl, scene } = useThree();
  const bounds = useBounds();

  useEffect(() => {
    setGL(gl);
  }, [gl]);

  useEffect(() => {
    setBounds(bounds);
  }, [bounds]);

  useEffect(() => {
    setScene(scene);
  }, [scene]);

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    // position the bottom of the model on the x-z-plane
    model.position.y -= box3.min.y
  }, [url, data]);

  useEffect(() => {
    const box3 = new Box3().setFromObject(model);
    const size = new Vector3();
    box3.getSize(size);
    const maximum = Math.max(size.x, size.z);
    if (maximum > 50) {
      // adjust the scale
      adjustScale(maximum);
    }
  }, [url]);

  return (
    <primitive
      ref={meshRef}
      object={model}
      scale={(data.scale === undefined || data.scale === 0) ? 1.0 : data.scale}
    />
  );
});

const ModelOBJZip = forwardRef(({ url, data, adjustScale, setGL, setBounds, setScene }, ref) => {
  const [urlObj, setUrlObj] = useState(null);
  const [urlMat, setUrlMat] = useState(null);

  const { meshRef } = ref;
  const { gl, scene } = useThree();
  const bounds = useBounds();
  useEffect(() => {
    setGL(gl);
  }, [gl]);

  useEffect(() => {
    setBounds(bounds);
  }, [bounds]);

  useEffect(() => {
    setScene(scene);
  }, [scene]);

  useEffect(() => {
    // fetch the zip-file, unzip it and check the files for support
    const fetchAndUnzip = async () => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const zip = await JSZip.loadAsync(blob);

        let objFound = false;
        let mtlFound = false;
        for (const relativePath of Object.keys(zip.files)) {
          const file = zip.files[relativePath];
          const fileBlob = await file.async('blob');
          if (relativePath.endsWith('.obj')) {
            setUrlObj(URL.createObjectURL(fileBlob));
            objFound = true;
          } else if (relativePath.endsWith('.mtl')) {
            setUrlMat(URL.createObjectURL(fileBlob));
            mtlFound = true;
          }
        }
        setModel(null);
        if (!objFound) {
          // if no .obj-file was found, set model to null
          setUrlObj(null);
          setUrlMat(null);
          setModel(null);
        }
      } catch (error) {
        console.error("Error fetching or unzipping the file:", error);
      }
    };
    fetchAndUnzip();
  }, [url])

  const [model, setModel] = useState(null);

  useEffect(() => {
    // load the .obj and .mtl file
    if (urlObj) {
      const loader = new OBJLoader();
      if (urlMat) {
        const mtlLoader = new MTLLoader();
        mtlLoader.load(urlMat, mat => {
          mat.preload();
          loader.setMaterials(mat);
          loader.load(urlObj, obj => {
            setModel(obj);
          });
        });
      } else {
        loader.load(urlObj, obj => {
          setModel(obj);
        });
      }
    }
  }, [urlMat, urlObj]);

  useEffect(() => {
    if (!model) return;
    const box3 = new Box3().setFromObject(model);
    // position the bottom of the model on the x-z-plane
    model.position.y -= box3.min.y
  }, [model, data]);

  useEffect(() => {
    if (!model) return;
    const box3 = new Box3().setFromObject(model);
    const size = new Vector3();
    box3.getSize(size);
    const maximum = Math.max(size.x, size.z);
    if (maximum > 50) {
      // adjust the scale
      adjustScale(maximum);
    }
  }, [model]);

  return model ? (
    <primitive
      ref={meshRef}
      object={model}
      scale={(data.scale === undefined || data.scale === 0) ? 1.0 : data.scale}
    />
  ) : null;
});

export default ModelPreview;
