import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

export default function SearchField({ onChange }) {
  const [search, setSearch] = useState("");

  const intl = useIntl();

  const handleChange = (e) => {
    setSearch(e.target.value);
    onChange(e.target.value);
  };

  return (
    <TextField
      placeholder={intl.formatMessage({ id: "SearchField.search" })}
      type="search"
      variant="outlined"
      fullWidth
      size="small"
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
