import { Alert, Snackbar } from "@mui/material";
import { createContext, useState } from "react";

const SnackContext = createContext();

const SnackProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [msg, setMessage] = useState("");

  const openSnackBar = (msg) => {
    setMessage(msg);
    setIsOpen(true);
  };

  const closeSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <SnackContext.Provider value={{ openSnackBar, closeSnackBar }}>
      {children}
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={closeSnackBar} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </SnackContext.Provider>
  );
};

export { SnackContext, SnackProvider };

