import { Box } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from "react";
import { useIntl } from "react-intl";
import ConcreteAssetOverview from "./ConcreteAssetOverview";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function AssetOverview({ productId, onAdd, onRemove, selectedAssets, getSelectedAssets }) {
  const [assets, setAssets] = useState(selectedAssets.map(asset => {
    return { ...asset, selected: true }
  }));
  const [selectedModel, setSelectedModel] = useState(null);
  const [tab, setTab] = useState(0);

  const intl = useIntl();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" centered >
        <Tab label={intl.formatMessage({ id: "AssetOverview.colors" })} {...a11yProps(0)} />
        <Tab label={intl.formatMessage({ id: "AssetOverview.materials" })} {...a11yProps(1)} />
        <Tab label={intl.formatMessage({ id: "AssetOverview.models" })} {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={tab} index={0}>
        <ConcreteAssetOverview productId={productId} onAdd={onAdd} onRemove={onRemove} type={"color"} getSelectedAssets={getSelectedAssets} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <ConcreteAssetOverview productId={productId} onAdd={onAdd} onRemove={onRemove} type={"material"} getSelectedAssets={getSelectedAssets} />
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={2}>
        <ConcreteAssetOverview productId={productId} onAdd={onAdd} onRemove={onRemove} type={"model"} getSelectedAssets={getSelectedAssets} />
      </CustomTabPanel>
    </Box>
  );
}
