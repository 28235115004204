import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ActionAreaCard from "../components/ActionAreaCard";
import axios from "axios";
import { API_URL } from "../utils/lib";

export default function ManufacturerProducts() {
  const { id } = useParams();


  const navigate = useNavigate();
  const [manufacturer, setManufacturer] = useState({});
  const [type, setType] = useState("color");
  const [data, setData] = useState({ product: [] });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `/manufacturer/${id}/product?page=0&size=10`
      );
      const data = await response.json();
      setData({ product: data });
      if (response.headers.get("x-page-count")) {
        setCount(response.headers.get("x-page-count") - 1);
      }
    };
    fetchData();

    const getManufacturer = async () => {
      const response = await axios.get(`${API_URL}/manufacturer/${id}`, { withCredentials: true });
      setManufacturer(response.data);
    };
    getManufacturer();
  }, [page]);

  return (
    <Container>
        <Button
          variant="contained"
          color="success"
          component={Link}
          to={`/manufacturer/${id}/product/new`}
          sx={{ float: "right" }}
        >
          Erstellen
        </Button>
        <h3>Produkte von: {manufacturer.name}</h3>
        {data.product._embedded &&
          data.product._embedded.productModelList.map((product, idx) => {
            return (
              <ActionAreaCard
                key={idx}
                title={product.name}
                description={product.description}
                src={undefined}
                handleClick={() => navigate("/product/" + product.id)}
              />
            );
          })}
    </Container>
  );
}
