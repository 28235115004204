import AbcIcon from '@mui/icons-material/Abc';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import NumbersIcon from '@mui/icons-material/Numbers';
import RuleIcon from '@mui/icons-material/Rule';
import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { forwardRef, Fragment, useImperativeHandle, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomToolTip from '../components/CustomToolTip';

const AttributeBox = forwardRef(({ isStatic, options, attributeProp, removeAttribute, getNextAttributeId, setAttributeDefaultValue }, ref) => {
  const [open, setOpen] = useState(false);
  const [showDetailedOptions, setShowDetailedOptions] = useState(attributeProp?.attribute?.type === 'PLAIN' ? false : true);
  const loading = open && options.length === 0;
  const [attribute, setAttribute] = useState({ ...attributeProp, attribute: { ...attributeProp.attribute } });

  const intl = useIntl();

  const getType = () => {
    if (attributeProp.attribute.type === 'NUMBER') {
      return 'number';
    } else {
      return 'text';
    }
  };

  useImperativeHandle(ref, () => ({
    getData: () => attribute,
  }));

  const onSelectKey = (value) => {
    setAttribute(at => {
      if (value === at.attribute.key) return at;
      let obj = {};
      if (value.key) {
        // the attribute is predefined (fetched from the server)
        obj = { ...at, attribute: value };
      } else {
        // the attribute is not defined yet, keep the old attribute-type
        let nextId = at.attribute.id;
        if (!at.attribute.id || !(at.attribute.id < 0)) {
          // generate new attribute-id, because current one is from server
          nextId = getNextAttributeId();
        }
        obj = { ...at, attribute: { type: at.attribute.type, key: value, id: nextId } };
      }
      if (at.attribute.type !== obj.attribute.type) {
        // the type of the attribute has changed, therefore set default value for this attribute
        setAttributeDefaultValue(obj);
      }
      return obj;
    });
  };

  const onSelectType = (type) => {
    let defaultObject = {
      valueText: null,
      valueBool: null,
      valueNumber: null
    }
    if (type === 'NUMBER') {
      defaultObject.valueNumber = 0;
    } else if (type === 'BOOL') {
      defaultObject.valueBool = false;
    } else {
      defaultObject.valueText = '';
    }
    setAttribute(attribute => {
      if (attribute.attribute.type !== type) {
        // search for attribute-option with same key and type
        const matchingAt = options.filter(at => at.key === attribute.attribute.key && at.type === type);
        let newId = getNextAttributeId();
        if (matchingAt.length > 0) {
          // there is one attribute-option with the same key and type, set its id and update attribute-counter
          newId = matchingAt[0].id;
        }
        return { ...attribute, attribute: { ...attribute.attribute, type: type, id: newId }, ...defaultObject };
      }
      return attribute;
    });
  };

  const onSelectValue = (value) => {
    setAttribute(attribute => {
      switch (attribute.attribute.type) {
        case 'TEXT':
          return { ...attribute, attribute: { ...attribute.attribute }, valueText: value };
        case 'NUMBER':
          return { ...attribute, attribute: { ...attribute.attribute }, valueNumber: value };
        case 'BOOL':
          return { ...attribute, attribute: { ...attribute.attribute }, valueBool: value };
      }
      return attribute;
    });
  };

  const textFieldStyle = {
    width: '90%'
  }

  const getValueFormElement = () => {
    let value;
    switch (attribute.attribute.type) {
      case 'BOOL':
        value = attribute.valueBool;
        break;
      case 'NUMBER':
        value = attribute.valueNumber;
        break;
      case 'TEXT':
        value = attribute.valueText;
        break;
      case 'PLAIN':
        value = '';
    }
    if (attribute.attribute.type === 'BOOL') {
      return (
        <TextField
          id="attribute-value"
          select
          disabled={isStatic}
          sx={textFieldStyle}
          label={intl.formatMessage({ id: "AttributeBox.value" })}
          variant="standard"
          required
          value={value}
          onChange={(event) => onSelectValue(event.target.value)}
        >
          <MenuItem key={false} value={false} ><FormattedMessage id="AttributeBox.false" /></MenuItem>
          <MenuItem key={true} value={true} ><FormattedMessage id="AttributeBox.true" /></MenuItem>
        </TextField>
      );
    } else {
      return (
        <TextField
          required
          sx={textFieldStyle}
          disabled={isStatic || attribute.attribute.type === 'PLAIN'}
          id="attribute-value"
          label={intl.formatMessage({ id: "AttributeBox.value" })}
          variant="standard"
          type={getType()}
          value={value}
          onChange={(event) => onSelectValue(event.target.value)}
        />
      );
    }
  }

  return (
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={3}>
        <Autocomplete
          freeSolo
          value={attribute.attribute}
          inputValue={attribute.attribute.key}
          open={open}
          disabled={isStatic}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onInputChange={(event, value) => {
            if (event.type === 'click') {
              // ignore event-types when input is selected from drop-down, because then onChange will be called as well
              return;
            }
            onSelectKey(value);
          }}
          onChange={(event, value) => {
            if (!value) {
              onSelectKey('');
              return;
            }
            if (value.key && value.type !== 'PLAIN') {
              // the attribute is already known (from the server) and not 'PLAIN'
              // show detailed options
              setShowDetailedOptions(true);
            }
            onSelectKey(value);
          }}
          options={options}
          getOptionLabel={(option) => {
            if (option.key !== undefined) {
              return option.key;
            } else {
              return option;
            }
          }}
          id={`combo-box-key`}
          loading={loading}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              size="small"
              {...params}
              label={intl.formatMessage({ id: "AttributeBox.key" })}
              required
              sx={textFieldStyle}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            let icon = <BlockIcon sx={{ color: 'gray' }} />;
            switch (option.type) {
              case 'NUMBER':
                icon = <NumbersIcon sx={{ color: 'gray' }} />;
                break;
              case 'TEXT':
                icon = <AbcIcon sx={{ color: 'gray' }} />;
                break;
              case 'BOOL':
                icon = <RuleIcon sx={{ color: 'gray' }} />;
                break;
            }
            return (
              <li {...props} key={option.id} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                {option.key}
                {icon}
              </li>
            )
          }}
        >
        </Autocomplete>
      </Grid>
      {
        !showDetailedOptions &&
        <Grid item xs={1} container alignItems={'center'}>
          <CustomToolTip
            tooltip={<FormattedMessage id='AttributeBox.showDetailedOptions' />}
            placement={"bottom"}
          >
            <Button
              onClick={() => setShowDetailedOptions(true)}
              aria-label='show detailed options'
            >
              <AddCircleOutlineIcon fontSize='large' />
            </Button>
          </CustomToolTip>
        </Grid>
      }
      {showDetailedOptions && <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="attribute-type-select-label"><FormattedMessage id="AttributeBox.type" /></InputLabel>
          <Select
            labelId="attribute-type-select-label"
            id="attribute-type-select"
            disabled={isStatic}
            value={attribute.attribute.type}
            label={intl.formatMessage({ id: "AttributeBox.type" })}
            sx={textFieldStyle}
            onChange={(event) => onSelectType(event.target.value)}
          >
            <MenuItem value={'PLAIN'}><em><FormattedMessage id="AttributeBox.noValue" /></em></MenuItem>
            <MenuItem value={'TEXT'}><FormattedMessage id="AttributeBox.text" /></MenuItem>
            <MenuItem value={'NUMBER'}><FormattedMessage id="AttributeBox.number" /></MenuItem>
            <MenuItem value={'BOOL'}><FormattedMessage id="AttributeBox.bool" /></MenuItem>
          </Select>
        </FormControl>
      </Grid>}
      {showDetailedOptions && <Grid item xs={3} >
        {getValueFormElement()}
      </Grid>}
      <Grid item xs={2} container alignItems={'center'}>
        {!isStatic && <Button
          variant="outlined"
          onClick={removeAttribute}
          color="error"
          aria-label="delete"
        >
          <DeleteIcon />
        </Button>}
      </Grid>
    </Grid>
  );
});

export default AttributeBox;