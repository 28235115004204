import ClearIcon from "@mui/icons-material/Clear";
import { Button, IconButton } from "@mui/material";
import { useRef } from "react";

export default function Upload({ id, selectedFile, onChange, label, style, accept }) {
  const fileRef = useRef();

  const handleFileSelect = (event) => {
    onChange(event?.target?.files?.[0]);
  };

  const handleClear = (event) => {
    onChange(null);
  };

  return (
    <>
      <input ref={fileRef} accept={accept} hidden type="file" onChange={handleFileSelect} onClick={event => event.target.value = ''} />
      <Button sx={style} variant="outlined" onClick={() => fileRef.current?.click()} >{label}</Button>
      {selectedFile && (
        <IconButton aria-label="clear" color="error" onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      )}
    </>
  );
}
