import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Autocomplete, Box, Button, ButtonGroup, Checkbox, Chip, Container, FormControlLabel, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../components/ConfirmationDialog";
import SearchField from "../components/SearchField";
import useSnackbar from "../components/snackbar/useSnackBar";
import { API_URL } from '../utils/lib';

export default function ProductOverview({ isAdmin, userId }) {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [query, setQuery] = useState("");
  const [productsUpdater, setProductsUpdater] = useState(0);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [filterPublished, setFilterPublished] = useState(false);

  // favorites
  const [productFavorites, setProductFavorites] = useState([]);
  const [pageFavorites, setPageFavorites] = useState(1);
  const [countFavorites, setCountFavorites] = useState(0);
  const [pageSizeFavorites, setPageSizeFavorites] = useState(5);
  const [favoritesUpdater, setFavoritesUpdater] = useState(0);
  // state of the delete-prompt
  const [isOpen, setIsOpen] = useState({ state: false, id: null });

  const navigate = useNavigate();

  const intl = useIntl();

  const assetTypes = [
    {
      id: 2,
      name: intl.formatMessage({ id: 'Product.material' }),
      param: "MATERIAL"
    },
    {
      id: 3,
      name: intl.formatMessage({ id: 'Product.model' }),
      param: "MODEL"
    },
    {
      id: 4,
      name: intl.formatMessage({ id: "Product.color" }),
      param: "COLOR"
    }
  ]

  /**
   * Fetches paginated favorite products.
   */
  useEffect(() => {
    let active = true;
    axios
      .get(`${API_URL}/product?page=${pageFavorites - 1}&size=${pageSizeFavorites}&favorites=true`, { withCredentials: true })
      .then(function (response) {
        if (!active) return;
        setProductFavorites(response.data.content);
        setCountFavorites(response.data.totalPages);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        navigate('/login');
      });
    return () => {
      active = false;
    }
  }, [navigate, pageFavorites, pageSizeFavorites, favoritesUpdater]);

  /**
   * Fetches paginated products with filter if specified.
   */
  useEffect(() => {
    let active = true;

    let fetchURL = '/product';
    fetchURL += `?page=${page - 1}&size=${pageSize}`;
    if (query) {
      fetchURL += `&search=${query}`
    }
    if (selectedManufacturer) {
      fetchURL += `&manufacturers=${selectedManufacturer}`;
    }
    if (selectedFilterType !== '' && selectedCategory !== '') {
      if (selectedFilterType === 2) {
        fetchURL += '&assetTypes=' + categoryOptions.find(x => x.id === selectedCategory).param;
      } else {
        fetchURL += `&${getCategoryParamName(selectedFilterType)}=${selectedCategory}`
      }
    };
    if (selectedAttributes.length > 0) {
      fetchURL += '&attributeKeys=';
      selectedAttributes.forEach((at, i, arr) => {
        fetchURL += at;
        if (i < arr.length - 1) {
          fetchURL += ',';
        }
      });
    }
    fetchURL += `&published=${filterPublished}`
    axios
      .get(API_URL + fetchURL, { withCredentials: true })
      .then(function (response) {
        if (!active) return;
        setProducts(response.data.content);
        setCount(response.data.totalPages);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        navigate('/login');
      })
      .finally(function () {
        // always executed
      });

    return () => {
      active = true;
    }
  }, [query, page, pageSize, selectedManufacturer, selectedCategory, selectedAttributes, filterPublished, productsUpdater]);

  useEffect(() => {
    let active = true;
    axios
      .get(`${API_URL}/manufacturer/all`, { withCredentials: true })
      .then(response => {
        if (!active) return;
        if (response?.data) {
          setManufacturerOptions(response.data);
        } else {
          setManufacturerOptions([]);
        }
      })
      .catch(error => {
        console.log(error);
      });
    axios
      .get(`${API_URL}/attribute`, { withCredentials: true })
      .then(response => {
        if (!active) return;
        setAttributeOptions(response.data);
      })
      .catch(error => {
        console.log(error);
      });
    return () => {
      active = false;
    };
  }, []);

  const handleSearch = (value) => {
    setPage(1);
    setQuery(value);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeManufacturer = (event) => {
    setSelectedManufacturer(event.target.value);
    setPage(1);
  }

  const handleChangeAttributes = (value) => {
    setSelectedAttributes(value);
  }

  const handleClearFilter = () => {
    setSelectedManufacturer('');
    setSelectedFilterType('');
    setSelectedCategory('');
    setSelectedAttributes([]);
    setCategoryOptions([]);
  }

  const handleChangeFilterType = (event) => {
    setSelectedFilterType(event.target.value);
    setSelectedCategory('');
    setCategoryOptions([]);
    let fetchURL;
    switch (event.target.value) {
      case 0:
        fetchURL = `${API_URL}/asset/category`;
        break;
      case 1:
        fetchURL = `${API_URL}/object/all`;
        break;
      case 2:
        setCategoryOptions(assetTypes);
        break;
      default:
    }
    if (fetchURL) {
      axios
        .get(fetchURL, { withCredentials: true })
        .then(response => {
          if (response?.data) {
            setCategoryOptions(response.data);
          } else {
            setCategoryOptions([]);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    setPage(1);
  };

  const getCategoryName = (value) => {
    switch (value) {
      case 0:
        return intl.formatMessage({ id: "Product.assetCategory" });
      case 1:
        return intl.formatMessage({ id: "Product.object" });
      case 2:
        return intl.formatMessage({ id: "Product.assetType" });
      default:
        return '';
    }
  };

  const getCategoryParamName = (value) => {
    switch (value) {
      case 0:
        return 'assetCategories';
      case 1:
        return 'objects'
      case 2:
        return 'assetTypes';
      default:
        return '';
    }
  };

  // handles opening of deletion-prompt
  const handleOpen = (id) => {
    setIsOpen({ state: true, id: id });
  };

  // handles closing of deletion-prompt
  const handleClose = () => {
    setIsOpen({ state: false, id: null });
  };

  // snackbar for when deletion is not successful
  const { openSnackBar, closeSnackBar } = useSnackbar();

  // dialog that prompts a delete
  const dialog = useMemo(() => {
    let d = (
      <ConfirmationDialog
        title={intl.formatMessage({ id: "Product.deletionQuestion" })}
        content={intl.formatMessage({ id: "Overview.deletionInfo" })}
        isOpen={isOpen.state}
        handleAccept={() => handleDelete(isOpen.id)}
        handleClose={handleClose}
      />
    );
    if (isOpen.state) {
      return d;
    }
  }, [isOpen]);

  // deletes a product
  function handleDelete(id) {
    axios
      .delete(`${API_URL}/product/${id}`, { withCredentials: true })
      .then((response) => {
        // handle the response
        handleClose();
        setProductsUpdater(old => old + 1);
        setFavoritesUpdater(old => old + 1);
      })
      .catch((error) => {
        // handle errors
        console.log(error);
        handleClose();
        openSnackBar(intl.formatMessage({ id: "Overview.deletionFailure" }));
      });
  }

  const handleFavoriteChange = (product) => (event) => {
    setProducts(old => old.map(p => p.id === product.id ? { ...p, isFavorite: event.target.checked } : p));
    axios
      .put(`${API_URL}/product/${product.id}/favorite?value=${event.target.checked}`, { withCredentials: true })
      .then(response => {
        // reload favorites
        setPageFavorites(1);
        setFavoritesUpdater(old => old + 1);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const getProductList = (products) => {
    return products.map((product, idx) => (
      <TableRow
        key={idx}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {product.name}
        </TableCell>
        <TableCell align="right">{product.description}</TableCell>
        <TableCell align="right">{product.productId}</TableCell>
        <TableCell align="right">{product.manufacturer?.name}</TableCell>
        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} align="right">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Checkbox icon={<StarBorderIcon />} checkedIcon={<StarIcon />} checked={product.isFavorite} onChange={handleFavoriteChange(product)} />
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                component={Link}
                color="primary"
                aria-label="edit"
                to={"/product/" + product.id}
              >
                <EditIcon />
              </Button>
              {(isAdmin || product.ownerId === userId) && <Button
                component={Link}
                onClick={() => handleOpen(product.id)}
                color="error"
                aria-label="delete"
              >
                <DeleteIcon />
              </Button>}
            </ButtonGroup>
          </Box>
        </TableCell>
      </TableRow>
    ))
  };

  return (
    <Container>
      <Button
        variant="contained"
        color="success"
        component={Link}
        to="/product/new"
        sx={{ float: "right" }}
      >
        <FormattedMessage id="Overview.create" />
      </Button>
      <h3><FormattedMessage id="Product.heading" /></h3>

      <h3><FormattedMessage id="Product.favorites" /></h3>
      <Pagination
        size="large"
        count={countFavorites}
        page={pageFavorites}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={(event, value) => setPageFavorites(value)}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="Product.name" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.description" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.articleNumber" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.manufacturer" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Overview.actions" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getProductList(productFavorites)}
            {dialog}
          </TableBody>
        </Table>
      </TableContainer>

      <h3><FormattedMessage id="Product.allProducts" /></h3>

      <SearchField onChange={(value) => handleSearch(value)} />
      <div style={{ display: 'flex', alignItems: 'center' }} >
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="manufacturer-select-standard-label"><FormattedMessage id="Product.manufacturer" /></InputLabel>
          <Select
            labelId="manufacturer-select-standard-label"
            id="manufacturer-select-standard"
            value={selectedManufacturer}
            onChange={handleChangeManufacturer}
            label={intl.formatMessage({ id: "Product.manufacturer" })}
          >
            <MenuItem value="">
              <em><FormattedMessage id="Product.none" /></em>
            </MenuItem>
            {manufacturerOptions.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="filter-type-select-standard-label"><FormattedMessage id="Product.filterType" /></InputLabel>
          <Select
            labelId="filter-type-select-standard-label"
            id="filter-type-select-standard"
            value={selectedFilterType}
            onChange={handleChangeFilterType}
            label={intl.formatMessage({ id: "Product.filterType" })}
          >
            <MenuItem value="">
              <em><FormattedMessage id="Product.none" /></em>
            </MenuItem>
            <MenuItem value={0}><FormattedMessage id="Product.assetCategory" /></MenuItem>
            <MenuItem value={1}><FormattedMessage id="Product.object" /></MenuItem>
            <MenuItem value={2}><FormattedMessage id="Product.assetType" /></MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="filter-value-select-standard-label">{getCategoryName(selectedFilterType)}</InputLabel>
          <Select
            labelId="filter-value-select-standard-label"
            id="filter-value-select-standard"
            value={selectedCategory}
            onChange={handleChangeCategory}
            label={getCategoryName(selectedFilterType)}
          >
            {categoryOptions.map(x => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)}
          </Select>
        </FormControl>
        <Autocomplete
          sx={{ m: 1, width: '100%' }}
          multiple
          id="tags-filled"
          options={attributeOptions.map(at => at.key)}
          defaultValue={[]}
          value={selectedAttributes}
          freeSolo
          onChange={(event, value) => handleChangeAttributes(value)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={intl.formatMessage({ id: "Product.attributes" })}
              placeholder={intl.formatMessage({ id: "Product.attributes" })}
            />
          )}
        />
        <Button
          variant="contained"
          color="error"

          sx={{ float: "right" }}
          style={{ marginLeft: 'auto' }}
          onClick={handleClearFilter}
        >
          <ClearSharpIcon />
        </Button>
      </div>
      <FormControlLabel
        sx={{ m: 1 }}
        value="end"
        control={<Switch color="primary" checked={filterPublished} onChange={event => {
          // switch back to first page
          setPage(1);
          setFilterPublished(event.target.checked)
        }} />}
        label={intl.formatMessage({ id: "Product.filterPublished" })}
        labelPlacement="end"
      />

      <Pagination
        size="large"
        count={count}
        page={page}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={handleChange}
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><FormattedMessage id="Product.name" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.description" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.articleNumber" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Product.manufacturer" /></TableCell>
              <TableCell align="right"><FormattedMessage id="Overview.actions" /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getProductList(products)}
            {dialog}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
