import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import ActionAreaCard from "../components/ActionAreaCard";
import ComboBox from "../components/ComboBox";
import CustomDialog from "../components/CustomDialog";
import ImageBox from "../components/ImageBox";
import useSnackbar from '../components/snackbar/useSnackBar';
import DataTable from '../data/DataTable';
import { API_URL, jsonBlob } from '../utils/lib';
import AssetOverview from "./AssetOverview";
import AttributeBox from "./AttributeBox";
import ProductFiles from "./ProductFiles";
import ProductLink from './ProductLink';
import ProductObjects from './ProductObjects';

export default function Product({ isAdmin, userId }) {
  const { manufacturer_id, id } = useParams();

  const [open, setOpen] = useState(false);
  const [openDataFileChooser, setOpenDataFileChooser] = useState(false);
  const [openPreviewPictureChooser, setOpenPreviewPictureChooser] = useState(false);
  const [manufacturer, setManufacturer] = useState({ id: undefined, name: "" });
  const [product, setProduct] = useState({
    name: "",
    description: "",
    productId: "",
    publicFlag: false,
    isFavorite: false
  });

  const isStatic = id !== 'new' && !isAdmin && product.ownerId !== userId;

  const [assets, setAssets] = useState([]);
  const [fetchedAssets, setFetchedAssets] = useState([]);
  const [diffAssets, setDiffAssets] = useState([]);
  const [previewPicture, setPreviewPicture] = useState({});
  const [deletePreviewPicture, setDeletePreviewPicture] = useState(false);

  // states for extra images / pdfs
  const [extraData, setExtraData] = useState([]);
  const [dataToRemove, setDataToRemove] = useState([]);

  // manage loading-state
  const [isLoading, setIsLoading] = useState(false);

  // list that holds all attribute-suggestions from the server
  const [attributeOptions, setAttributeOptions] = useState([]);
  // object that holds references to all attribute-react-elements
  const childRefs = useRef({});
  // object that holds all attributes
  const [attributes, setAttributes] = useState({});

  /**
   * Fetches all attribute-data from the AttributBox-elements that are children.
   * @returns 
   */
  const getAllAttributeData = () => {
    return Object.values(childRefs.current).map((ref) => ref?.getData()).filter(d => d);
  }

  const attributeId = useRef(-1);
  // list that holds all attributes that should be deleted
  const [attributesToDelete, setAttributesToDelete] = useState([]);
  // list that holds all objects
  const [objects, setObjects] = useState([]);
  // list that holds all objects to delete
  const [obToDelete, setObToDelete] = useState([]);

  const [linkElement, setLinkElement] = useState(null);

  // snackbar for information
  const { openSnackBar, closeSnackBar } = useSnackbar();

  const navigate = useNavigate();

  const intl = useIntl();

  useEffect(() => {
    let active = true;
    if (id !== "new") {
      setIsLoading(true);
      axios.get(`${API_URL}/product/${id}`, { withCredentials: true })
        .then(response => {
          if (!active) return;
          parseFetchedData(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          if (!active) return;
          setIsLoading(false);
        });
    }

    if (manufacturer_id) {
      axios.get(`${API_URL}/manufacturer/${manufacturer_id}`, { withCredentials: true })
        .then(response => {
          if (!active) return;
          setManufacturer(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    }

    axios.get(`${API_URL}/attribute`, { withCredentials: true })
      .then(response => {
        if (!active) return;
        setAttributeOptions([...response.data]);
      })
      .catch(error => {
        console.log(error);
      });

    return () => {
      // cancel state-setting-methods
      active = false;
    }
  }, [manufacturer_id, id]);

  const handleClickOpen = () => {
    setDiffAssets([...assets]);
    setOpen(true);
  };

  const handleClose = (value) => {
    setDiffAssets([]);
    setOpen(false);
  };

  const handleAccept = () => {
    setAssets([...diffAssets]);
    setDiffAssets([]);
    setOpen(false);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProduct({
      ...product,
      [name]: value,
    });
  };

  const removeData = (data) => {
    if (data.id >= 0 && !data.notUploaded) {
      // delete-request must be sent in next submit
      setDataToRemove(old => [...old, data]);
    }
    setExtraData(oldData => oldData.filter(d => d.id !== data.id));
  }


  const handleDataSelect = (data) => () => {
    setExtraData(oldData => {
      if (oldData.some(d => d.id === data.id)) {
        // if already selected, do not add data
        return [...oldData];
      } else {
        // if not already selected, add data
        data.notUploaded = true;
        return [...oldData, data];
      }
    });
    setOpenDataFileChooser(false);
  }

  /**
   * Parses fetched product-data into states.
   * @param {*} data 
   */
  const parseFetchedData = (data) => {
    setProduct(data);
    setAssets(data.assets);
    setFetchedAssets(data.assets);
    setManufacturer(data.manufacturer);
    setExtraData(data.data);
    setLinkElement(data.link);
    // parse fetched attributes
    parseFetchedAttributes(data.attributes);
    // uploaded indicates that this object is associated with this product on the server
    setObjects(data.objects.map(ob => ({ ...ob, uploaded: true })));
    if (data.previewPicture) {
      setPreviewPicture({ ...data.previewPicture, url: `${API_URL}/product/${id}/previewPicture`, file: undefined });
    }
    axios.get(`${API_URL}/attribute`, { withCredentials: true })
      .then(response => {
        setAttributeOptions([...response.data]);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let attributeData = getAllAttributeData();
    if (!checkObjectAttributesConstraint(attributeData)) {
      return;
    }
    setIsLoading(true);
    const formData = new FormData();

    product.assets = assets;
    product.manufacturer = manufacturer;

    if (id === "new") {
      const productObj = { ...product };
      productObj.data = extraData;
      if (previewPicture.id) {
        productObj.previewPicture = previewPicture;
      } else {
        productObj.previewPicture = null;
      }
      productObj.link = linkElement;
      formData.append("product", jsonBlob(productObj));
      extraData.filter(data => data.id < 0).forEach((file) => {
        formData.append(`data`, file);
      });
      if (previewPicture.file) {
        formData.append("preview_picture", previewPicture.file);
      }
      formData.append("attributes", jsonBlob(getAttributeFormData(attributeData)));
      formData.append("objects", jsonBlob(objects.filter(ob => !ob.uploaded)));
      axios
        .post(`${API_URL}/product`, formData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true
        })
        .then((response) => {
          // handle the response
          navigate('/products');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // put the current product
      // first delete all assets, that are in fetchedAssets, but not assets
      const dAssets = fetchedAssets.filter(a => assets.filter(asset => a.id === asset.id).length === 0);
      const assetDeletionPromise = dAssets.map(a => {
        return axios
          .delete(`${API_URL}/product/${id}/asset/${a.id}`, { withCredentials: true })
          .then(response => {
            // nothing should happen in case of response
          })
          .catch(error => {
            console.log(error);
          });
      });
      // now delete all extra files that are in dataToRemove
      const dataDeletionPromise = dataToRemove.map(data => {
        return axios
          .delete(`${API_URL}/product/${id}/data/${data.id}`, { withCredentials: true })
          .then(response => {
            // nothing should happen
          })
          .catch(error => {
            console.log(error);
          });
      });
      const objectDeletionPromise = obToDelete.map(ob => {
        return axios
          .delete(`${API_URL}/product/${id}/object/${ob.id}`, { withCredentials: true })
          .then(response => {
            // nothing should happen
            setObToDelete(old => old.filter(o => o.id !== ob.id));
          })
          .catch(error => {
            console.log(error);
          })
      });
      // get the attribute-form-data
      let attributeFormData = getAttributeFormData(attributeData);
      // check if some attribute-instances might not need to be deleted, but rather changed
      let deletionAttributes = [...attributesToDelete];
      attributeFormData = attributeFormData.map(fd => {
        if (!fd.attribute.id) {
          // new attribute
          return fd;
        }
        // index of attribute-instance that can be reused
        const index = deletionAttributes.findIndex(at => at.attribute.id === fd.attribute.id);
        if (index !== -1) {
          // set reusable attribute-instance id
          fd = { ...fd, id: deletionAttributes[index].id };
          // remove attribute-instance from deletion-attributes
          deletionAttributes.splice(index, 1);
          setAttributesToDelete(old => old.filter(at => at.id !== fd.id));
        }
        return fd;
      });
      // delete all objects before deleting all attributes (due to restrictions)
      const attributeDeletionPromise = Promise.all([objectDeletionPromise])
        .then(() => {
          return Promise.all(deletionAttributes.map(at => {
            return axios
              .delete(`${API_URL}/product/${id}/attribute/${at.id}`, { withCredentials: true })
              .then(response => {
                // nothing should happen
                setAttributesToDelete(old => old.filter(a => a.id !== at.id));
              })
              .catch(error => {
                console.log(error);
              })
          }));
        });
      const previewPictureDeletionPromise = new Promise((resolve, reject) => {
        if (deletePreviewPicture) {
          axios
            .delete(`${API_URL}/product/${id}/previewPicture`, { withCredentials: true })
            .then(response => {
              setDeletePreviewPicture(false);
              resolve();
            })
            .catch(error => {
              console.log(error);
              reject();
            })
        } else {
          resolve();
        }
      });
      setDataToRemove([]);
      // now update the current product
      const productObj = { ...product };
      productObj.data = extraData;
      if (previewPicture.id) {
        productObj.previewPicture = previewPicture;
      } else {
        productObj.previewPicture = null;
      }
      productObj.link = linkElement;
      formData.append("product", jsonBlob(productObj));
      formData.append("assets", jsonBlob(product.assets));
      extraData.filter(data => data.id < 0).forEach((file) => {
        formData.append(`data`, file);
      });
      if (previewPicture.file) {
        formData.append("preview_picture", previewPicture.file);
      }
      formData.append("attributes", jsonBlob(attributeFormData));
      formData.append("objects", jsonBlob(objects.filter(ob => !ob.uploaded)));
      // wait for all deletions to terminate
      const deletionPromise = Promise.all([...assetDeletionPromise, ...dataDeletionPromise, attributeDeletionPromise, previewPictureDeletionPromise]);
      deletionPromise
        .then(() => {
          axios
            .put(`${API_URL}/product/${id}`, formData, {
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "multipart/form-data"
              },
              withCredentials: true
            })
            .then(response => {
              navigate('/products');
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
    }
  };

  const onSelectManufacturer = (manufacturer) => {
    setManufacturer(manufacturer);
  };

  const onAdd = asset => {
    setDiffAssets(oldAssets => {
      if (oldAssets.filter(a => a.id === asset.id).length === 0) {
        // if not already part of product-assets, add
        if (asset.assetType === "MODEL") {
          // remove all other assets of type "MODEL"
          oldAssets = oldAssets.filter(a => a.assetType !== "MODEL");
        } else if (asset.assetType === "MATERIAL") {
          // remove all other assets of type "MATERIAL"
          oldAssets = oldAssets.filter(a => a.assetType !== "MATERIAL");
        }
        return [...oldAssets, { ...asset, notUploaded: true }];
      } else {
        // already part of product-assets, no addition necessary
        return [...oldAssets];
      }
    });
  };

  const onRemove = asset => {
    setDiffAssets(oldAssets => {
      if (oldAssets.filter(a => a.id === asset.id).length > 0) {
        // if already part of product-assets, remove
        return oldAssets.filter(a => a.id !== asset.id);
      } else {
        // not part of product-assets, no removal necessary
        return [...oldAssets];
      }
    });
  }

  const removeAsset = asset => {
    setAssets(oldAssets => oldAssets.filter(a => a.id !== asset.id));
  };

  const getSelectedAssets = useCallback(() => {
    return diffAssets;
  }, [diffAssets]);

  const handleSelectPreviewPicture = (data) => () => {
    data.notUploaded = true;
    data.url = `${API_URL}/data/${data.id}/file`
    setPreviewPicture(data);
    setOpenPreviewPictureChooser(false);
  }

  const handleDeletePreviewPicture = () => {
    if (previewPicture.notUploaded) {
      // must not delete from server
      setPreviewPicture({});
    } else {
      // delete in next submit
      setPreviewPicture({});
      setDeletePreviewPicture(true);
    }
  }

  const setAttributeDefaultValue = (attribute) => {
    attribute.valueBool = null;
    attribute.valueText = null;
    attribute.valueNumber = null;
    switch (attribute.attribute.type) {
      case 'BOOL':
        attribute.valueBool = false;
        break;
      case 'TEXT':
        attribute.valueText = '';
        break;
      case 'NUMBER':
        attribute.valueNumber = 0;
        break;
    }
  }

  const addAttribute = () => {
    setAttributes(old => {
      old = { ...old };
      const id = getNextAttributeId();
      old[id] = { id: id, valueText: '', valueNumber: null, valueBool: null, attribute: { type: 'PLAIN', key: '' }, uploaded: false, objectCounter: 0 };
      return old;
    });
  };

  const removeAttribute = (id) => () => {
    const at = childRefs.current[id].getData();
    if (at && at.uploaded) {
      setAttributesToDelete(old => [...old, at]);
    }
    setAttributes(old => {
      old = { ...old };
      delete old[id];
      return old;
    })
    delete childRefs.current[id];
  }

  /**
   * Adds the specified object to the list of associated objects.
   * @param {*} ob 
   */
  const addObject = (ob) => {
    // add object
    setObjects(old => [...old, { ...ob }]);
    // add attributes from object to product
    addAttributesFromObject(ob.attributes);
  }

  /**
   * Removes the specified object from the list of associated objects.
   * @param {*} ob 
   * @returns 
   */
  const removeObject = (ob) => () => {
    setObjects(old => old.filter(o => o.id !== ob.id));
    if (ob.uploaded) {
      setObToDelete(old => [...old, ob]);
    }
  }

  const getNextAttributeId = () => {
    return attributeId.current--;
  }

  const handlePublicChange = (event) => {
    setProduct(oldProduct => ({ ...oldProduct, publicFlag: event.target.checked }));
  }

  const handleFavoriteChange = (event) => {
    if (isStatic && id) {
      // make immediate request to server
      axios
        .put(API_URL + `/product/${id}/favorite?value=${event.target.checked}`, { withCredentials: true })
        .then(response => {
          setProduct(oldProduct => ({ ...oldProduct, isFavorite: response.data.isFavorite }));
        })
        .catch(error => {
          console.log(error);
        })
    } else {
      setProduct(oldProduct => ({ ...oldProduct, isFavorite: event.target.checked }));
    }
  }

  const parseFetchedAttributes = (attributes) => {
    setAttributes(() => {
      let obj = {};
      attributes.map(at => {
        obj[at.id] = { ...at, uploaded: true, oldAt: at };
      });
      return obj;
    });
  }

  /**
   * Adds all attributes that have not already been set on this product.
   */
  const addAttributesFromObject = (attributes) => {
    const attributeInstances = attributes.map(at => {
      at = { id: getNextAttributeId(), valueText: null, valueNumber: null, valueBool: null, attribute: { ...at }, uploaded: false };
      // set the attributes default value
      setAttributeDefaultValue(at);
      return at;
    });
    // add all default-attribute-instances which attributes have not been added yet to the product
    setAttributes(old => {
      old = { ...old };
      const listIds = Object.keys(old);
      const obj = {};
      attributeInstances.map(at => {
        if (!listIds.includes(at.attribute.id)) {
          obj[at.id] = at;
        }
      });
      return { ...old, ...obj };
    });
  }

  /**
   * Check that all attributes of all objects have a corresponding attribute-instance.
   * @returns 
   */
  const checkObjectAttributesConstraint = (attributeData) => {
    let satisfied = true;
    const idObj = {};
    const addObj = {};
    Object.values(attributeData).forEach(at => {
      if (at.attribute.id) {
        idObj[at.attribute.id] = true;
      }
    })
    objects.forEach(ob => {
      ob.attributes.forEach(at => {
        if (!idObj[at.id]) {
          satisfied = false;
          addObj[at.id] = at;
        }
      });
    });
    if (!satisfied) {
      addAttributesFromObject(Object.values(addObj));
      openSnackBar(intl.formatMessage({ id: "EditProduct.attributeObjectWarning" }));
    }
    return satisfied;
  }

  /**
   * Transforms the attributes that have been changed to objects that can be used in form-data.
   * @returns the form-data
   */
  const getAttributeFormData = (attributeData) => {
    const data = attributeData.map(attribute => {
      let at = { ...attribute, attribute: { ...attribute.attribute }, oldAt: attribute.oldAt };
      if (at.attribute.id && at.attribute.id < 0) {
        at.attribute.id = null;
      }
      const oldAt = at.oldAt;
      if (!oldAt) {
        // not yet uploaded
        at.id = null;
        return at;
      }
      // check if there is an attribute from the server with the same key and type
      let option = attributeOptions.find(option => option.key === at.attribute.key && option.type === at.attribute.type);
      if (option) {
        // set this option as attribute
        at.attribute = option;
      } else {
        // no option matches
        at.attribute.id = null;
      }
      if (at.attribute.id !== oldAt.attribute.id || at.valueBool !== oldAt.valueBool || at.valueNumber !== oldAt.valueNumber || at.valueText !== oldAt.valueText) {
        // update is required
        at.oldAt = null;
        return at;
      }
      return null;
    }).filter(at => at !== null);
    return data;
  }

  const attributeList = useMemo(() => {
    return Object.values(attributes).map((at) => {
      return (
        <AttributeBox
          isStatic={isStatic}
          options={attributeOptions}
          key={at.id}
          attributeProp={at}
          removeAttribute={removeAttribute(at.id)}
          getNextAttributeId={getNextAttributeId}
          setAttributeDefaultValue={setAttributeDefaultValue}
          ref={(el) => (childRefs.current[at.id] = el)}
        ></AttributeBox>
      );
    });
  }, [attributes, attributeOptions, isStatic]);

  const textFieldStyle = {
    width: '90%'
  }

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        <FormattedMessage id="EditProduct.heading" />
      </Typography>
      <CustomDialog
        title={intl.formatMessage({ id: "EditProduct.addPreviewPicture" })}
        content={<DataTable selectable={true} pdf={false} svg={false} image={true} onSelect={handleSelectPreviewPicture} />}
        isOpen={openPreviewPictureChooser}
        handleAccept={() => setOpenPreviewPictureChooser(false)}
        handleClose={() => setOpenPreviewPictureChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <CustomDialog
        title={intl.formatMessage({ id: "EditProduct.chooseAssets" })}
        content={<AssetOverview productId={id} onAdd={onAdd} onRemove={onRemove} selectedAssets={assets} getSelectedAssets={getSelectedAssets} />}
        isOpen={open}
        handleAccept={handleAccept}
        handleClose={handleClose}
        maxWidth={"xl"}
      />
      <CustomDialog
        title={intl.formatMessage({ id: "EditProduct.addPicturePdf" })}
        content={<DataTable selectable={true} onSelect={handleDataSelect} />}
        isOpen={openDataFileChooser}
        handleAccept={() => setOpenDataFileChooser(false)}
        handleClose={() => setOpenDataFileChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        marginTop={4}
      >
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          sx={{ paddingBottom: 2, marginBottom: 2 }}
        >
          <Grid container flexDirection='column' justifyContent='center' alignItems='center' item xs={12} md={6} >
            {previewPicture.url && <ImageBox url={previewPicture.url} uploaded={previewPicture.notUploaded !== true} onDelete={handleDeletePreviewPicture} disabled={isStatic} />}
            {/* <input accept=".jpg, .jpeg, .png" ref={fileRefPreview} hidden type="file" onChange={event => handleSelectPreviewPicture(event?.target?.files[0])} onClick={event => event.target.value = ''} /> */}
            {!isStatic && <Button variant="outlined" sx={{ marginTop: 1 }} onClick={() => setOpenPreviewPictureChooser(true)} ><FormattedMessage id="EditProduct.addPreviewPicture" /></Button>}
          </Grid>
        </Box>
        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, width: "100%", paddingBottom: 2, marginBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"name"}
              label={intl.formatMessage({ id: "EditProduct.name" })}
              type="text"
              value={product.name}
              sx={textFieldStyle}
              onChange={(event) => {
                handleInputChange(event);
              }}
              disabled={isStatic}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"productId"}
              label={intl.formatMessage({ id: "EditProduct.articleNumber" })}
              type="text"
              value={product.productId}
              sx={textFieldStyle}
              onChange={(event) => {
                handleInputChange(event);
              }}
              disabled={isStatic}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ComboBox
              label={intl.formatMessage({ id: "EditProduct.manufacturer" })}
              selection={manufacturer}
              onSelect={onSelectManufacturer}
              required={false}
              url={`/manufacturer/all`}
              style={textFieldStyle}
              idTag={"manufacturer"}
              disabled={isStatic}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"description"}
              label={intl.formatMessage({ id: "EditProduct.description" })}
              type="text"
              multiline
              value={product.description}
              sx={{
                width: {
                  xs: '90%',
                  sm: '95%',
                  md: '95%'
                }
              }}
              onChange={(event) => {
                handleInputChange(event);
              }}
              disabled={isStatic}
            />
          </Grid>

          <ProductObjects isStatic={isStatic} objects={objects} addObjectToProduct={addObject} removeObject={removeObject} />

          <Grid item xs={12}>
            <ProductLink link={linkElement} setLink={setLinkElement} isStatic={isStatic} />
          </Grid>

          {!isStatic && <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel control={<Checkbox checked={product.publicFlag} onChange={handlePublicChange} />} label={intl.formatMessage({ id: "Edit.published" })} />
          </Grid>}

          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox icon={<StarBorderIcon />} checkedIcon={<StarIcon />} checked={product.isFavorite} onChange={handleFavoriteChange} />
              }
              label={
                product.isFavorite ? intl.formatMessage({ id: "EditProduct.removeFromFavorites" }) : intl.formatMessage({ id: "EditProduct.addToFavorites" })
              } />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" >
              <FormattedMessage id="EditProduct.attributeHeading" />
            </Typography>
          </Grid>

          {!isStatic && <Grid item xs={12} >
            <Button variant="contained" onClick={addAttribute}><FormattedMessage id="EditProduct.addAttribute" /></Button>
          </Grid>}

          {attributeList}
        </Grid>


        <Grid container spacing={3} marginBottom={3}>
          {!isStatic && <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              <FormattedMessage id="EditProduct.addAsset" />
            </Button>
          </Grid>}
          {assets.map((asset, idx) => {
            const assetType = asset.assetType.toLowerCase();
            let previewPictureURL = API_URL;
            if (!asset.hasFullAccess) {
              // if the user does not have full access to the asset, he must fetch the preview-picture through the product
              previewPictureURL += `/product/${id}`
            }
            previewPictureURL += `/${assetType}/${asset.id}/previewPicture?quality=thumb`;
            return (
              <Grid key={idx} item xs={12} sm={6} md={3}>
                <ActionAreaCard
                  title={asset.name}
                  description={asset.description}
                  src={
                    asset.previewPictureId &&
                    previewPictureURL
                  }
                  color={asset.hex && asset.hex}
                  handleClick={() => {
                    navigate(`/product/${id}/${assetType}/${asset.id}`);
                  }
                  }
                  onDelete={() => removeAsset(asset)}
                  disabled={isStatic}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={3}>
          {!isStatic && <Grid item xs={12}>
            {/* <input multiple accept=".jpg, .jpeg, .png, .svg, .pdf" ref={fileRef} hidden type="file" onChange={event => handleFileSelect(event?.target?.files)} onClick={event => event.target.value = ''} /> */}
            <Button variant="outlined" onClick={() => {
              // fileRef.current?.click()
              setOpenDataFileChooser(true);
            }}><FormattedMessage id="EditProduct.addPicturePdf" /></Button>
          </Grid>}
          <Grid item xs={12}>
            <ProductFiles removeData={removeData} extraData={extraData} disabled={isStatic} id={id} />
          </Grid>
        </Grid>

        {!isStatic && <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>}
      </Box>
    </Container>
  );
}
