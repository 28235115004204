import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext({
  locale: '',
  setLocale: () => { },
});

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  // Supported languages
  const supportedLanguages = ['en', 'de'] //, 'it', 'fr', 'es', 'pl', 'cs', 'pt'];

  // Determine the initial language
  const browserLanguage = navigator.language.split('-')[0]; // Extract language code
  const initialLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'de';

  const [locale, setLocale] = useState(initialLanguage);

  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      {children}
    </LanguageContext.Provider>
  );
};