import Box from '@mui/material/Box';
import React, { useEffect } from 'react';

const Intro = ({ onEnd }) => {
  const gifUrl = `/img/intro.gif?${new Date().getTime()}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      onEnd();
    }, 2700);

    return () => clearTimeout(timer);
  }, [onEnd]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5000
      }}
    >
      <img
        src={gifUrl}
        alt="Intro Animation"
        style={{
          maxWidth: '80%', // Maximum width as a percentage of the container
          maxHeight: '80%', // Maximum height as a percentage of the container
          objectFit: 'contain' // Maintain the aspect ratio of the GIF
        }}
      />
    </Box>
  );
};

export default Intro;