import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Checkbox, CircularProgress, Container, Fab, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import ComboBox from "../components/ComboBox";
import CustomDialog from "../components/CustomDialog";
import ImageBox from "../components/ImageBox";
import useSnackbar from "../components/snackbar/useSnackBar";
import DataTable from "../data/DataTable";
import { API_URL } from "../utils/lib";
import mapTypes from "./MapUtil";
import MaterialImageCard from "./MaterialImageCard";
import MaterialPreview from "./MaterialPreview";

export default function EditMaterial({ isAdmin, userId }) {
  const { pid, id } = useParams();

  const [data, setData] = useState({ opacity: 1.0, publicFlag: false, object: { id: undefined, name: '' } });
  // materials for react three fiber
  const [materials, setMaterials] = useState({});
  const [materialMaps, setMaterialMaps] = useState([]);
  const [mapsToRemove, setMapsToRemove] = useState([]);
  const [previewPicture, setPreviewPicture] = useState({});
  const [deletePreviewPicture, setDeletePreviewPicture] = useState(false);
  const nextId = useRef(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openMapFileChooser, setOpenMapFileChooser] = useState(false);
  const [openPreviewPictureChooser, setOpenPreviewPictureChooser] = useState(false);

  const isStatic = id !== 'new' && !isAdmin && data.ownerId !== userId;

  let fetchPrefix = '';
  if (pid) {
    fetchPrefix += `/product/${pid}`;
  }

  const fileRef = useRef();

  const navigate = useNavigate();

  const intl = useIntl();

  // snackbar for user-information
  const { openSnackBar } = useSnackbar();

  const normalForm = {
    name: {
      type: "text",
      label: intl.formatMessage({ id: "EditMaterial.name" }),
    }
  }

  const specialForm = {
    color: { type: "color", label: "color" },
    emissiveColor: { type: "color", label: "emissiveColor" },
    emissiveIntensity: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "emissiveIntensity",
    },
    roughness: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "roughness",
    },
    metalness: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "metalness",
    },
    reflectivity: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "reflectivity",
    },
    clearcoat: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "clearcoat",
    },
    clearcoatRoughness: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "clearcoatRoughness",
    },
    sheen: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "sheen",
    },
    sheenRoughness: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "sheenRoughness",
    },
    transmission: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "transmission",
    },
    thickness: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "thickness",
    },
    opacity: {
      type: "number",
      inputProps: { min: 0.0, max: 1.0, step: 0.01 },
      label: "opacity",
    },
    flatShading: { type: "check", label: "flatShading" },
    bumpScale: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "bumpScale",
    },
    normalScaleX: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "normalScaleX",
    },
    normalScaleY: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "normalScaleY",
    },
    aoMapIntensity: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "aoMapIntensity",
    },
    displacementScale: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "displacementScale",
    },
    displacementBias: {
      type: "number",
      inputProps: { step: 0.01 },
      label: "displacementBias",
    },
    side: {
      type: "number",
      select: true,
      label: 'side',
      children: [
        {
          value: null,
          name: "Null"
        },
        {
          value: 0,
          name: 'Front'
        },
        {
          value: 1,
          name: 'Back'
        },
        {
          value: 2,
          name: 'Double'
        }
      ]
    },
    blending: {
      type: "number",
      select: true,
      label: 'blending',
      children: [
        {
          value: 0,
          name: "NoBlending"
        },
        {
          value: 1,
          name: 'NormalBlending'
        },
        {
          value: 2,
          name: 'AdditiveBlending'
        },
        {
          value: 3,
          name: 'SubtractiveBlending'
        },
        {
          value: 4,
          name: 'MultiplyBlending'
        },
        {
          value: 5,
          name: 'CustomBlending'
        }
      ]
    },
    transparent: {
      label: 'transparent',
      type: 'checkbox',
      select: true,
      children: [
        {
          value: false,
          name: intl.formatMessage({ id: "EditMaterial.false" })
        },
        {
          value: true,
          name: intl.formatMessage({ id: "EditMaterial.true" })
        }
      ]
    }
  };

  function jsonBlob(obj) {
    return new Blob([JSON.stringify(obj)], {
      type: "application/json",
    });
  }

  useEffect(() => {
    let materials = {};
    materialMaps.forEach((m) => {
      mapTypes.forEach((map) => {
        if (m.type === map.type) {
          materials[map.three] = m.url
            ? m.url + '?quality=high'
            : API_URL + fetchPrefix + `/material/${id}/map/${m.id}?quality=high`;
        }
      });
    });
    setMaterials(materials);
  }, [materialMaps, id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // check if every asset has a different type
    if (!checkUniqueTypes()) {
      openSnackBar(intl.formatMessage({ id: "EditMaterial.mapWarning" }));
      return;
    }

    const formData = new FormData();

    // filter out newly added maps that have not been added on the server yet
    const materialMapsArr = materialMaps.filter(map => map.id < 0);
    const materialObj = { ...data };
    if (materialObj.object && !materialObj.object.id) {
      materialObj.object = null;
    }
    formData.append("material", jsonBlob({ ...materialObj, maps: materialMapsArr, previewPicture: !previewPicture.id || previewPicture.uploaded ? null : previewPicture }));

    setIsLoading(true);
    if (id === "new") {
      axios
        .post(`${API_URL}/material`, formData, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true
        })
        .then((response) => {
          navigate('/materials');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      const mapDeletionPromise = mapsToRemove.map(map => {
        return axios
          .delete(`${API_URL}/material/${id}/map/${map.id}`, { withCredentials: true })
          .then(() => {
            // removal successful
          })
          .catch((error) => {
            console.log(error);
          });
      });
      let previewPictureDeletionPromise = new Promise(resolve => resolve());
      if (deletePreviewPicture && data.previewPicture) {
        previewPictureDeletionPromise = axios
          .delete(`${API_URL}/material/${id}/previewPicture`, { withCredentials: true })
          .catch(error => {
            console.log(error);
          });
      }
      const deletionPromise = Promise.all([...mapDeletionPromise, previewPictureDeletionPromise]);
      deletionPromise
        .then(() => {
          axios
            .put(`${API_URL}/material/${id}`, formData, {
              headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "multipart/form-data"
              },
              withCredentials: true
            })
            .then((response) => {
              // handle the response
              navigate('/materials');
            })
            .catch((error) => {
              // handle errors
              console.log(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
    }
  };

  function getFormElement(name, properties, data) {
    return (
      <TextField
        required={name === 'name'}
        variant="outlined"
        size="small"
        name={name}
        sx={textFieldStyle}
        label={properties.label}
        type={properties.type}
        InputProps={properties.inputProps}
        InputLabelProps={{ shrink: true }}
        value={data[name] == null ? "" : data[name]}
        onChange={(event) => {
          updateData(event);
        }}
        select={properties.select ? true : false}
        disabled={isStatic}
      >
        {properties.children?.map((x, i) => (
          <MenuItem key={i} value={x.value}>
            {x.name}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  const updateData = (e) => {
    const fieldName = e.target.name;
    setData((existingValues) => ({
      ...existingValues,
      [fieldName]: e.target.value,
    }));
  };

  const removeMap = (map) => {
    if (map.id >= 0) {
      // map is already uploaded, association must be removed on server
      setMapsToRemove((old) => [...old, map]);
    }
    // remove from materialMaps
    setMaterialMaps((materialMaps) => materialMaps.filter((m) => m.id !== map.id));
  };

  const uploadDone = (d) => {
    if (d === undefined) {
      return;
    }
    setData(d);

    if (d.previewPicture) {
      setPreviewPicture({ url: API_URL + fetchPrefix + `/material/${id}/previewPicture?quality=high`, uploaded: true });
    }
    setMaterialMaps(d.maps);
    // reset maps to remove
    setMapsToRemove([]);
  };

  const checkUniqueTypes = () => {
    const usedTypes = {};
    let unique = true;
    materialMaps.forEach(map => {
      if (!map.type || usedTypes[map.type]) {
        unique = false;
      } else {
        usedTypes[map.type] = true;
      }
    });
    return unique;
  };

  const checkUnique = (mapId, mapType) => {
    let unique = true;
    materialMaps.forEach(mm => {
      if (mm.id === mapId) {
        return;
      }
      if (mm.type === mapType) {
        unique = false;
      }
    });
    return unique;
  };

  const handleTypeChange = (mapId, mapType) => {
    if (checkUnique(mapId, mapType)) {
      if (id !== undefined && id !== "new" && mapId !== undefined && mapId >= 0) {
        setIsLoading(true);
        axios
          .put(`${API_URL}/material/${id}/map/${mapId}`, { id: mapId, type: mapType }, { withCredentials: true })
          .then((response) => {
            // handle the response
            setMaterialMaps(response.data.maps);
          })
          .catch((error) => {
            // handle errors
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setMaterialMaps(oldData => oldData.map(map => {
          if (map.id === mapId) {
            map.type = mapType;
          }
          return map;
        }));
      }
      return true;
    } else {
      openSnackBar(intl.formatMessage({ id: "EditMaterial.mapWarning" }));
      return false;
    }
  };

  useEffect(() => {
    if (id !== "new") {
      setIsLoading(true);
      axios
        .get(API_URL + fetchPrefix + `/material/${id}`, { withCredentials: true })
        .then(response => {
          uploadDone(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const materialMapList = materialMaps.map((m) => {
    return (
      <Grid key={m.id} item xs={12} sm={6} md={3}>
        <MaterialImageCard
          url={m.url ? m.url + '?quality=thumb' : API_URL + fetchPrefix + `/material/${id}/map/${m.id}?quality=thumb`}
          id={m.id}
          map={m}
          handle={uploadDone}
          removeMap={removeMap}
          handleTypeChange={handleTypeChange}
          disabled={isStatic}
        />
      </Grid>
    );
  });

  /**
   * Method to be called when the file-chooser for PreviewPicture has selected data.
   * @param {*} data 
   * @returns 
   */
  const handlePreviewPictureSelect = (data) => () => {
    setPreviewPicture({ ...data, url: `${API_URL}/data/${data.id}/file`, uploaded: false });
    setOpenPreviewPictureChooser(false);
  }

  /**
   * Removes the preview-picture locally from the Material.
   */
  const handlePreviewPictureDelete = () => {
    setPreviewPicture({});
    if (id !== undefined && id !== "new") {
      // should delete preview-picture when data is submitted to server
      setDeletePreviewPicture(true);
    }
  }

  const handleMapSelect = (data) => () => {
    let val = {
      url: `${API_URL}/data/${data.id}/file`,
      image: { ...data },
      id: nextId.current,
    };
    if (materialMaps.length === 0) {
      // make the first selected picture of type 'map', all others must be manually selected
      val.type = 'map'
    }
    nextId.current = nextId.current - 1;
    setMaterialMaps((existingValues) => [...existingValues, val]);
    setOpenMapFileChooser(false);
  }

  const handleChangeDetails = (event) => {
    setShowDetails(event.target.checked);
  }

  const handlePublicChange = (event) => {
    setData(oldData => ({ ...oldData, publicFlag: event.target.checked }));
  };

  const textFieldStyle = {
    width: '90%'
  }

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
          <FormattedMessage id="EditMaterial.heading" />
        </Typography>
        <FormControlLabel control={<Switch
          checked={showDetails}
          onChange={handleChangeDetails}
          inputProps={{ 'aria-label': 'controlled' }}
        />}
          label={intl.formatMessage({ id: "Material.profSettingsLabel" })}
          labelPlacement="start" />
      </div>
      <CustomDialog
        title={intl.formatMessage({ id: "EditMaterial.choosePreviewPicture" })}
        content={<DataTable selectable={true} pdf={false} svg={false} image={true} onSelect={handlePreviewPictureSelect} />}
        isOpen={openPreviewPictureChooser}
        handleAccept={() => setOpenPreviewPictureChooser(false)}
        handleClose={() => setOpenPreviewPictureChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <CustomDialog
        title={intl.formatMessage({ id: "EditMaterial.chooseMaterialMap" })}
        content={<DataTable selectable={true} pdf={false} svg={false} image={true} onSelect={handleMapSelect} />}
        isOpen={openMapFileChooser}
        handleAccept={() => setOpenMapFileChooser(false)}
        handleClose={() => setOpenMapFileChooser(false)}
        maxWidth={"xl"}
        hideConfirmation={true}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3} alignItems='center' sx={{ marginBottom: 2, width: '100%' }}>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                height: 500,
                backgroundColor: "grey.300",
                borderRadius: 3
              }}
            >
              <MaterialPreview mats={materials} data={data} scale={1.0} />
            </Box>
          </Grid>
          <Grid container flexDirection='column' justifyContent='center' alignItems='center' item xs={12} md={3}>
            {!isStatic && <Button
              onClick={() => setOpenPreviewPictureChooser(true)}
              variant="outlined"
              sx={{ marginBottom: 1 }}
            >
              <FormattedMessage id="EditMaterial.choosePreviewPicture" />
            </Button>}
            {previewPicture.url &&
              <ImageBox url={previewPicture.url} uploaded={previewPicture.uploaded === true ? true : false} onDelete={handlePreviewPictureDelete} disabled={isStatic} />
            }
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='flex-start' sx={{ border: '1px dashed black', borderRadius: 3, marginTop: 3, paddingBottom: 3, marginBottom: 6, width: '100%' }}>
          <Grid item xs={12} >
            <Typography variant="h5" gutterBottom sx={{ margin: 0 }}>
              <FormattedMessage id="EditMaterial.mapHeading" />:
            </Typography>
          </Grid>
          {materialMapList}
          {!isStatic && <Grid
            container
            key={"add"}
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Fab
              color="primary"
              variant="extended"
              aria-label="add"
              onClick={() => setOpenMapFileChooser(true)}
            >
              <AddIcon />
              <FormattedMessage id="EditMaterial.mapHeading" />
            </Fab>
          </Grid>}
        </Grid>

        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, width: "100%", paddingBottom: 2 }}>
          {Object.keys(normalForm).map((key, i) => (
            <Grid item xs={12} sm={6} md={3} key={i}>
              {getFormElement(key, normalForm[key], data)}
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3} key={Object.keys(normalForm).length}>
            <ComboBox
              label={intl.formatMessage({ id: "EditMaterial.objectType" })}
              selection={data.object}
              onSelect={(mt => {
                setData(existingValues => ({
                  ...existingValues,
                  object: mt
                }));
              })}
              required={false}
              url={`/object/all`}
              style={textFieldStyle}
              idTag="object"
              disabled={isStatic}
            />
          </Grid>
          {isAdmin && <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel control={<Checkbox checked={data.publicFlag} onChange={handlePublicChange} />} label={intl.formatMessage({ id: "Edit.published" })} />
          </Grid>}
          {showDetails && Object.keys(specialForm).map((key, i) => {
            const offset = Object.keys(normalForm).length + 1;
            return (
              <Grid item xs={12} sm={6} md={3} key={i + offset}>
                {getFormElement(key, specialForm[key], data)}
              </Grid>
            );
          }
          )}
        </Grid>
        {!isStatic && <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>}
      </Box>
    </Container>
  );
}
