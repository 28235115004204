import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();

// Define a utility to load the locale data
const loadLocaleData = async (locale) => {
  switch (locale) {
    case 'de':
      return import('../locales/de.json');
    case 'cs':
      return import('../locales/cz.json');
    case 'en':
      return import('../locales/en.json');
    case 'es':
      return import('../locales/es.json');
    case 'fr':
      return import('../locales/fr.json');
    case 'it':
      return import('../locales/it.json');
    case 'pl':
      return import('../locales/pl.json');
    case 'pt':
      return import('../locales/pt.json');
    default:
      return import('../locales/de.json');
  }
};

// Custom error handling function to prevent unwanted error logging to console.
export const intlErrorHandler = () => {
  // console.log();
};

// Define a function to create an intl instance
export const createIntlInstance = async (locale) => {
  const messages = await loadLocaleData(locale);
  return createIntl({ locale, messages: messages.default }, cache);
};
