import {
    Container
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import DataTable from "./DataTable";

export default function Data() {
    return (
        <Container>
            <h3><FormattedMessage id="Data.heading" /></h3>
            <DataTable />
        </Container>
    );
}
