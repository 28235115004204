import { useTheme } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import LanguagePicker from "./components/LanguagePicker";
import { API_URL, clearAuthentication } from "./utils/lib";

const pages = [
  {
    name: "Navbar.home",
    path: "/",
  },
  {
    name: "Navbar.manufacturers",
    path: "/manufacturers",
  },
  {
    name: "Navbar.products",
    path: "/products",
  },
  {
    name: "Navbar.colors",
    path: "/colors",
  },
  {
    name: "Navbar.materials",
    path: "/materials",
  },
  {
    name: "Navbar.models",
    path: "/models",
  },
  {
    name: "Navbar.data",
    path: "/data"
  },
  {
    name: "Navbar.categories",
    path: "/categories",
  },
  {
    name: "Navbar.objects",
    path: "/objects"
  }
];

const adminPages = [1, 7, 8];

export default function Navbar({ children, setLoggedIn, loggedIn, setIsAdmin, isAdmin, setUserId }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [drawerState, setDrawerState] = React.useState(false);

  const theme = useTheme();
  const intl = useIntl();

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState(open);
  };

  useEffect(() => {
    axios
      .post(API_URL + '/checkAccessToken', undefined, { withCredentials: true })
      .then(response => {
        setLoggedIn(true);
        setIsAdmin(response.data.isAdmin);
        setUserId(response.data.userId);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 403) {
          navigate('/login');
          setLoggedIn(false);
        }
      });
  }, []);

  const authenticationButton = useMemo(() => {
    if (loggedIn) {
      return <Button sx={{ marginLeft: 'auto' }} component={Link} to={"/"} onClick={() => {
        setLoggedIn(false);
        setIsAdmin(false);
        setUserId(null);
        axios
          .post(API_URL + '/logout', undefined, { withCredentials: true })
          .then(response => {
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            clearAuthentication();
            setLoggedIn(false);
          })
      }} color="inherit">Logout</Button>
    } else {
      return <Button sx={{ marginLeft: 'auto' }} component={Link} to={"/login"} color="inherit"><FormattedMessage id="Navbar.login" /></Button>;
    }
  }, [loggedIn]);

  const drawerList = () => (
    <Box
      sx={{ width: 'auto', height: '100%', backgroundColor: theme.palette.primary.main }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((page, idx) => {
          if (!isAdmin && adminPages.includes(idx)) {
            return;
          }
          return (
            <ListItem key={idx} disablePadding>
              <ListItemButton onClick={() => navigate(page.path)}>
                <ListItemText
                  primary={intl.formatMessage({ id: page.name })}
                  primaryTypographyProps={{ style: { color: '#ffffff' } }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'relative', md: 'none' } }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, idx) => {
              if (!isAdmin && adminPages.includes(idx)) {
                return;
              }
              return (
                <Button
                  component={NavLink}
                  to={page.path}
                  key={idx}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  <FormattedMessage id={page.name} />
                </Button>
              );
            })}
          </Box>
          <LanguagePicker />
          {authenticationButton}
        </Toolbar>
        <Drawer
          open={drawerState}
          onClose={toggleDrawer(false)}
          anchor="left"
        >
          {drawerList()}
        </Drawer>
      </AppBar>
      <Outlet />
    </>
  );
}
