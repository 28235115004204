import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../utils/lib";

export default function EditColor({ isAdmin, userId }) {
  const { pid, id } = useParams();

  const [data, setData] = useState({ publicFlag: false });
  const [isLoading, setIsLoading] = useState(false);

  const isStatic = id !== 'new' && !isAdmin && data.ownerId !== userId;

  const intl = useIntl();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (id === "new") {
      axios
        .post(`${API_URL}/color`, data, { withCredentials: true })
        .then((response) => {
          navigate('/colors');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .put(`${API_URL}/color/${id}`, data, { withCredentials: true })
        .then((response) => {
          navigate('/colors');
        })
        .catch((error) => {
          // handle errors
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id !== "new") {
      let fetchURL = '';
      if (pid) {
        fetchURL = `/product/${pid}`;
      }
      fetchURL += `/color/${id}`
      setIsLoading(true);
      axios
        .get(API_URL + fetchURL, { withCredentials: true })
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const changeData = (event) => {
    setData(oldData => ({ ...oldData, [event.target.name]: event.target.value }));
  };

  const handlePublicChange = (event) => {
    setData(oldData => ({ ...oldData, publicFlag: event.target.checked }));
  };

  const textFieldStyle = {
    width: '90%'
  }

  return (
    <Container maxWidth='xl' >
      {isLoading && <Box
        sx={{
          marginTop: 3,
          marginBottom: 3,
          position: "fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <CircularProgress color="primary" />
      </Box>}
      <Typography variant="h4" gutterBottom sx={{ marginTop: 2 }}>
        <FormattedMessage id="EditColor.heading" />
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3} alignItems="center" sx={{ border: '1px dashed black', borderRadius: 3, margin: 0, width: "100%", paddingBottom: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"name"}
              label={intl.formatMessage({ id: "EditColor.name" })}
              type="text"
              sx={textFieldStyle}
              value={data.name || ''}
              onChange={changeData}
              disabled={isStatic}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              size="small"
              name="hex"
              label={intl.formatMessage({ id: "EditColor.colorRGB" })}
              type="color"
              sx={textFieldStyle}
              value={data.hex || '#000000'}
              onChange={changeData}
              disabled={isStatic}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              size="small"
              name={"ral"}
              label={intl.formatMessage({ id: "EditColor.ral" })}
              type="text"
              sx={textFieldStyle}
              value={data.ral || ''}
              onChange={changeData}
              disabled={isStatic}
            />
          </Grid>
          {isAdmin && <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel control={<Checkbox checked={data.publicFlag} onChange={handlePublicChange} />} label={intl.formatMessage({ id: "Edit.published" })} />
          </Grid>}
        </Grid>
        {!isStatic && <Button sx={{ marginTop: 2, marginBottom: 2 }} variant="contained" type="submit">
          <FormattedMessage id="Edit.save" />
        </Button>}
      </Box>
    </Container>
  );
}
