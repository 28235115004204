import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";


const ProductLink = memo(({ link, setLink, isStatic }) => {
  const [productLink, setProductLink] = useState('');

  const intl = useIntl();

  /**
   * Add link to list (link is specified in productLink).
   */
  const addLink = () => {
    // link must not be empty
    if (!productLink) return;
    setLink(productLink);
    setProductLink('');
  }

  /**
   * Remove link. 
   */
  const removeLink = () => {
    setLink(null);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addLink();
    }
  }

  return (
    <Grid container item xs={12} spacing={3} rowSpacing={1} alignItems="center">
      <Grid item xs={12} >
        <Typography variant="h6" >
          <FormattedMessage id="EditProduct.linkHeading" />
        </Typography>
      </Grid>
      {!isStatic &&
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            id="product-link"
            label={intl.formatMessage({ id: "EditProduct.productLink" })}
            placeholder={intl.formatMessage({ id: "EditProduct.enterProductLink" })}
            size="small"
            variant="outlined"
            type="text"
            sx={{
              width: {
                xs: '95%',
                sm: '90%',
                md: '95%'
              }
            }}
            onKeyDown={handleKeyDown}
            value={productLink}
            onChange={event => setProductLink(event.target.value)}
          />
        </Grid>}
      {!isStatic &&
        <Grid item xs={6} sm={6} md={6}>
          <Button variant="outlined" onClick={addLink} sx={{
            width: {
              xs: '80%',
              sm: '50%',
              md: '50%'
            }
          }} ><FormattedMessage id="EditProduct.addProductLink" /></Button>
        </Grid>}
      <Grid item xs={12} md={6} container flexDirection="column">
        {link && <Grid item sx={{ marginTop: 1, marginBottom: 1 }}>
          {!isStatic && <Button
            variant="outlined"
            onClick={() => removeLink()}
            color="error"
            aria-label="delete"
          >
            <DeleteIcon />
          </Button>}
          <a style={{ marginLeft: 5 }} target="_blank" href={'https://' + link}>{link}</a>
        </Grid>}
      </Grid>
    </Grid>
  );
});

export default ProductLink;