import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Pagination, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/lib";
import DataCard from "./DataCard";

export default function DataTable({ onSelect, selectable = false, pdf = true, svg = true, image = true}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  // number of pages
  const [count, setCount] = useState(0);
  const [data, setData] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const [pdfFlag, setPdfFlag] = useState(pdf);
  const [svgFlag, setSvgFlag] = useState(svg);
  const [imageFlag, setImageFlag] = useState(image);
  const [isLoading, setIsLoading] = useState(false);

  const fileRef = useRef();

  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let active = true;
    setIsLoading(true);
    setData([0, 1, 2, 3, 4, 5, 6, 7, 8]);
    axios
      .get(API_URL + `/data?pdf=${pdfFlag}&svg=${svgFlag}&image=${imageFlag}&page=${page - 1}&size=${pageSize}`, { withCredentials: true })
      .then(response => {
        if (!active) return;
        setData(response.data.content);
        setCount(response.data.totalPages);
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        navigate('/login');
      });
    return () => {
      active = false;
    }
  }, [updateCounter, page, pageSize, pdfFlag, svgFlag, imageFlag]);

  const onDelete = (data) => () => {
    axios
      .delete(API_URL + `/data/${data.id}`, { withCredentials: true })
      .then(() => {
        setUpdateCounter(counter => counter + 1);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const onDownload = (data) => () => {
    axios
      .get(API_URL + `/data/${data.id}/file`, { responseType: 'blob', withCredentials: true })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.download = data.fileName;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.log(error);
      })
  }

  const handleFileSelect = (files) => {
    const reqs = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append('file', files[i]);
      reqs.push(axios.post(API_URL + '/data', formData, { withCredentials: true }));
    }
    const promises = Promise.all(reqs);

    promises
      .then(() => {
        setUpdateCounter(counter => counter + 1);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <Box>
      <Pagination
        size="large"
        count={count}
        page={page}
        sx={{ marginTop: '5px', marginBottom: '5px' }}
        onChange={handlePageChange}
      />
      <Box sx={{ marginBottom: 1 }}>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          {pdf && <FormControlLabel control={<Checkbox checked={pdfFlag} onChange={(event) => setPdfFlag(event.target.checked)} />} label="PDF" />}
          {svg && <FormControlLabel control={<Checkbox checked={svgFlag} onChange={(event) => setSvgFlag(event.target.checked)} />} label="SVG" />}
          {image && <FormControlLabel control={<Checkbox checked={imageFlag} onChange={(event) => setImageFlag(event.target.checked)} />} label="JPG / PNG" />}
        </FormGroup>
      </Box>
      <Grid container spacing={1} justifyContent="center" sx={{ border: '1px dashed black', borderRadius: 3, width: "100%", paddingBottom: 1, paddingRight: 1, marginBottom: 2 }} >
        <Grid item xs={12} >
          <input multiple accept=".jpg, .jpeg, .png, .svg, .pdf" ref={fileRef} hidden type="file" onChange={event => handleFileSelect(event?.target?.files)} onClick={event => event.target.value = ''} />
          <Box sx={{
            backgroundColor: "#bfc9c2", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center",
            borderStyle: "dashed", boxSizing: "border-box", flexDirection: "column", "&:hover": { cursor: "pointer" }, padding: '16px'
          }} onClick={() => {
            fileRef.current?.click()
          }}>
            <CloudUploadIcon />
            <p><FormattedMessage id="Data.uploadData" /></p>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ height: "100%" }} >
          <Box sx={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
            <Grid container spacing={1} sx={{}}>
              {
                data.map(data => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={data.id}>
                      {
                        isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height="300px" />
                        ) : (
                          <DataCard data={data} selectable={selectable} onSelect={onSelect} onDelete={onDelete} onDownload={onDownload} />
                        )
                      }
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </Grid >
      </Grid >
    </Box >
  );
}