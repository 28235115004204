import {
  OrbitControls,
  PerspectiveCamera
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { forwardRef, memo, useEffect, useRef } from "react";
import { Vector3 } from "three";

const ManualModelPreview = forwardRef(({ model, scale, zoomValue }, ref) => {

  const cameraRef = useRef();

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(...zoom(zoomValue, new Vector3(1.0, 1.5, 2.0)));
    }
  }, []);

  useEffect(() => {
    if (cameraRef.current) {
      cameraRef.current.position.set(...zoom(zoomValue, cameraRef.current.position));
    }
  }, [zoomValue]);

  const zoom = (value, pos) => {
    pos = pos.normalize();
    return [pos.x * value, pos.y * value, pos.z * value]
  };

  return (
    <Canvas
      color={0xffffff}
      linear
    >
      <ambientLight color={0xffffff} intensity={0.66} />
      <pointLight
        position={[0.0, 1.2, -1.2]}
        intensity={1}
        distance={10}
        decay={2}
        power={5}
        castShadow={true}
      />
      <Box scale={scale} model={model} ref={ref} />
      <PerspectiveCamera makeDefault ref={cameraRef} onUpdate={(self) => self.position.set(...zoom(zoomValue, new Vector3(1.0, 1.5, 2.0)))} />
      <OrbitControls makeDefault enablePan={false} />
      <gridHelper args={[50, 50]} />
    </Canvas>
  );
});

const Box = memo(forwardRef(({ scale, model }, ref) => {
  return (
    <mesh ref={ref} scale={[scale, scale, scale]} position={[0, model.height / 2, 0]}>
      <boxGeometry args={[model.length, model.height, model.depth]} />

      <meshPhysicalMaterial
        opacity={1}
      />
    </mesh>
  );
}));

export default ManualModelPreview;
