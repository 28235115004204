import React, { Component, useEffect } from 'react';
import './App.css';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

function Home() {
  return (
    <div>
      <Typography fontWeight="bold" sx={{ marginTop: 4 }} align='center' variant='h4'><FormattedMessage id="Home.welcome" /></Typography>
    </div>
  );
}
export default Home;